import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useSetModuleSystem } from '../../data/mutations/financingObject.mutations'
import { useGetFinObj } from '../../data/queries/financing.queries'
import { ModuleSystem } from '../../engines/bobpay/customerVerfication/bobpayCustomerVerification.types'
import { isSessionExpired, isSessionInvalid } from '../../shared/helpers'
import { mapStateLevels, StateLevels } from '../../shared/helpers.stateLevel'
import { FinancingObject } from '../../types/financing.types'
import { Loading } from '../scaffold/Loading'
import { NotFound } from '../scaffold/NotFound'

type errorDetailsType = {
  status: number
}

// =============================================================
// Polling context
// =============================================================

type PollFinancingDataContextType = {
  poll: (isActive: boolean) => void
}
const PollFinancingDataContext = createContext<PollFinancingDataContextType>({
  poll: () => {},
})
PollFinancingDataContext.displayName = 'PollFinancingDataContext'

export const usePollFinancingData = (): PollFinancingDataContextType => {
  return useContext(PollFinancingDataContext)
}

// =============================================================
// Financing object data context
// =============================================================

const FinObjDataContext = createContext<FinancingObject | undefined>(undefined)
FinObjDataContext.displayName = 'Fin Obj Data Context'

export const useFinObjData = (): FinancingObject => {
  const finObj = useContext(FinObjDataContext)
  if (!finObj) {
    throw new Error(`Financing object not found.`)
  }
  return finObj
}

export const useOptionalFinObj = (): FinancingObject | undefined => {
  const finObj = useContext(FinObjDataContext)
  return finObj
}

export const useStateLevels = (): { stateLevels: StateLevels } => {
  const finObj = useContext(FinObjDataContext)

  return { stateLevels: mapStateLevels({ financing: finObj }) }
}

// =============================================================
// Data Provider Component
// =============================================================

export const DataProvider = (props: { children: ReactNode }) => {
  const [isPolling, setIsPolling] = useState(false)

  const {
    data: finObj,
    error: finObjError,
    isLoading: isFinObjLoading,
    isError: isFinObjError,
    isRefetching: isFinObjRefetching,
  } = useGetFinObj({ isPolling })

  // =============================================================
  // Check for expired session
  // =============================================================
  const setModuleSystem = useSetModuleSystem()

  const errorDetailsObj = finObjError as errorDetailsType
  const isUnauthorized = finObjError ? errorDetailsObj.status === 401 : false

  useEffect(() => {
    if (isSessionInvalid()) {
      window.sessionStorage.clear()
      setModuleSystem(ModuleSystem.InvalidSMSLink)
    }

    if (isSessionExpired()) {
      window.sessionStorage.clear()
      setModuleSystem(ModuleSystem.ExpiredSMSLink)
    }
  }, [isUnauthorized])

  if (isFinObjError) {
    return <NotFound label='Financing object not found' />
  }

  if (isFinObjLoading && !isFinObjRefetching) {
    return <Loading label='fetching financing object' />
  }

  return (
    <PollFinancingDataContext.Provider value={{ poll: setIsPolling }}>
      {/*  <FinancingDataContext.Provider value={finObj ? finObj : undefined}> */}
      <FinObjDataContext.Provider value={finObj ? finObj : undefined}>
        {props.children}
      </FinObjDataContext.Provider>
      {/*   </FinancingDataContext.Provider> */}
    </PollFinancingDataContext.Provider>
  )
}

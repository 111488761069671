import {
  NavigationActiveType,
  NavigationLoading,
  NavigationPlacement,
  PartnerFrontendConfiguration,
  UserLanguage,
} from '../types/frontend.types'
import { brand } from '../styling/bob/bob.brand'
import { FieldStyle, StyleConfigDTO } from 'frr-web/lib/theme/configureBaseStyle'

export const bobStyleConfig: StyleConfigDTO = {
  colorBackgroundAccent: '0,145,178,1',
  colorBackgroundActive: '232, 246, 250',
  colorBackgroundHover: '242,242,242,0.6',
  colorBackgroundPrimary: '255,255,255,1',
  colorBackgroundSecondary: '242,242,242,1',

  colorAccent: '255,199,44,1',
  colorAccentHover: '255,189,34,1',
  colorActive: '0,145,178,1',
  colorDisabled: '156,156,156,1',
  colorError: '169,16,29,1',
  colorHover: '249,193,0,0.6',
  colorInput: '0,0,0,1',
  colorPrimary: '0,0,0,1',
  colorSecondary: '0,0,0,0.6',
  colorWarning: '255,197,61,1',

  colorButtonPrimary: '0,0,0,1',

  fontFamilyNormalUrl:
    'https://stbobfspfrontendassets.blob.core.windows.net/bobzeroassets/FoundryContext-Regular.woff2',
  fontFamilyNormalFormat: 'woff2',
  fontFamilyMediumUrl:
    'https://stbobfspfrontendassets.blob.core.windows.net/bobzeroassets/FoundryContext-Medium.woff2',
  fontFamilyMediumFormat: 'woff2',
  fontFamilyBoldUrl:
    'https://stbobfspfrontendassets.blob.core.windows.net/bobzeroassets/FoundryContext-Bold.woff2',
  fontFamilyBoldFormat: 'woff2',

  fontBaseSize: '10px',
  fontSizeP: '1.6rem',
  fontSizePSmall: '1.4rem',
  fontSizeLabel: '1.6rem',
  fontSizeSublabel: '1.2rem',
  fontSizeInput: '1.6rem',
  fontSizeTitle: '2.4rem',
  fontSizeTitleMobile: '2.2rem',

  headerBoxShadow: '0 0 8px 8px rgba(0, 0, 0, 0.04)',
  headerHeight: 84,
  headerHeightMobile: 72,
  headerLogoWidth: 256,
  headerLogoWidthMobile: 256,
  headerBackgroundColor: '65, 175, 200, 1',
  headerBackgroundColorDark: 'rgba(52, 58, 60, 1)',
  headerTitleColor: '255,255,255,1',
  headerTitleFontSize: '2.4rem',

  buttonFontSize: '2.0rem',
  buttonBorderRadius: 8,
  buttonWidth: 172,
  buttonHeight: 52,
  buttonStyle: FieldStyle.SoftEdge,

  formPaddingVertical: 12,
  formPaddingVerticalMobile: 4,
  formPaddingHorizontal: 48,
  formPaddingHorizontalMobile: 32,

  formRowHeight: 72,
  formRowBottomBorder: '1px solid rgba(0,0,0,0.1)',
  formFieldHeight: 48,
  formFieldBorderRadius: '5px',
  formFieldPaddingHorizontal: 12,
  formFieldStyle: FieldStyle.SoftEdge,

  formIconFilter: 'none',
}

export const defaultPartnerConfig: PartnerFrontendConfiguration = {
  styleConfig: bobStyleConfig,
  headerConfig: {
    address: undefined,
    FAQs: [], // FAQ Links
    favicon: 'bob-favicon.ico', // Fav icon
    logo: '/bob-logo-text-white.svg', // REMOVE and take from partner config
    showBobPageTitleLink: false,
    showFinalStepAnimation: false,
    showLanguageMenu: true, // Show language menu
    showLogoLink: true,
    showPageTitle: false,
    showSidebar: false,
    showSupportNumber: false, // Show support number in header
  },
  footerConfig: {
    hideFooter: true,
    showCopyright: false,
    showLanguageMenu: false,
    showSupportNumber: false,
  },
  trackingConfig: {
    bobGTMTrackerId: undefined,
    cookieBotDomainGroupId: undefined,
  },
  navigationConfig: {
    activeType: NavigationActiveType.Fill,
    placement: NavigationPlacement.AboveTitle,
    loadingAnimation: NavigationLoading.Animation1,
    showNavigationBelowTitle: false,
  },
  otherConfig: {
    disableLanguages: [],
    defaultLanguage: UserLanguage.EN,
    languageNamespacePartner: undefined,
    showHasPartnerBankAccountField: false,
  },
  ...brand,
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BobPartnerApp_DTOs_PartnerVariantFrontendConfigurationDTO } from '../models/BobPartnerApp_DTOs_PartnerVariantFrontendConfigurationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PartnerService {

    /**
     * @param hostname
     * @param clientCtx Client context of the request, corresponds to the BobPartnerVariantBaseId.
     * @returns BobPartnerApp_DTOs_PartnerVariantFrontendConfigurationDTO OK
     * @throws ApiError
     */
    public static getPartnerGetPartnerConfigurationByHostname(
        hostname: string,
        clientCtx?: string,
    ): CancelablePromise<BobPartnerApp_DTOs_PartnerVariantFrontendConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Partner/get_partner_configuration_by_hostname/{hostname}',
            path: {
                'hostname': hostname,
            },
            query: {
                'client_ctx': clientCtx,
            },
        });
    }

    /**
     * @param internalIdentifier
     * @param clientCtx Client context of the request, corresponds to the BobPartnerVariantBaseId.
     * @returns BobPartnerApp_DTOs_PartnerVariantFrontendConfigurationDTO OK
     * @throws ApiError
     */
    public static getPartnerGetPartnerConfigurationByPartnerVariantId(
        internalIdentifier: string,
        clientCtx?: string,
    ): CancelablePromise<BobPartnerApp_DTOs_PartnerVariantFrontendConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Partner/get_partner_configuration_by_partner_variant_id/{internalIdentifier}',
            path: {
                'internalIdentifier': internalIdentifier,
            },
            query: {
                'client_ctx': clientCtx,
            },
        });
    }

}

import { OptionGroup } from 'frr-web/lib/components/OptionGroup'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { getLanguageOptions, i18nLang } from '../../../shared/constants'
import { UserLanguage } from '../../../types/frontend.types'
import { useChangeLanuage } from '../../providers/UrlLanguage.provider'
import { detect } from 'detect-browser'
import { useProduct } from '../../context/PartnerProduct.context'
import { OnboardingChannel } from '../../../types/financing.types'
import { useWhitelabelNavigation } from '../../context/Navigation.context'
import { usePlatform } from 'frr-web/lib/hooks/usePlatform'
import { Notification } from '../../providers/Notification.provider'
import { useAppConfig } from '../../providers/AppConfigData.provider'

const browser = detect()

type Props = {
  open: boolean
  close: () => void
}

export const AppSidebar = (props: Props) => {
  const { appConfig } = useAppConfig()
  const { product, configuration } = useProduct()
  const { t, i18n } = useTranslation()
  const { isChangeLanguageDisabled } = useWhitelabelNavigation()
  const platform = usePlatform()

  const sidebarItems =
    ([OnboardingChannel.BPL_RETAIL, OnboardingChannel.BPL_ECOMMERCE].includes(
      product.onboardingChannel as OnboardingChannel,
    ) && [
      {
        label: 'menu.item.legal',
        externalLink: 'menu.item.legalLink',
      },
      // Show debug menu item only for DEV language
      ...(i18n.language === i18nLang[UserLanguage.DEV]
        ? [
            {
              label: 'menu.item.debug',
              onClick: () => {
                Notification.info(JSON.stringify({ platform, browser }), {
                  autoClose: false,
                })
              },
            },
          ]
        : []),
    ]) ||
    ([OnboardingChannel.CREDIT].includes(product.onboardingChannel as OnboardingChannel) && [
      {
        label: 'menu.item.legal',
        externalLink: 'menu.item.legalLink',
      },
      {
        label: 'menu.item.aboutBobFinance',
        externalLink: 'menu.item.aboutBobFinanceLink',
      },
      {
        label: 'menu.item.support',
        externalLink: 'menu.item.supportLink',
      },
    ]) ||
    []

  // Get url search params, set partner and leave the rest
  const changeLanguage = useChangeLanuage()

  return (
    <>
      {props.open && <OpacityScreen onClick={props.close} />}

      <SidebarWrapper $open={props.open} data-test-id='menu-sidebar'>
        <SidebarHeader>
          <br />
          <SidebarCloseIcon onClick={props.close} data-test-id='sidebar-close'>
            <img src='/close.svg' alt='arrow' />
          </SidebarCloseIcon>
        </SidebarHeader>

        <SidebarList>
          {sidebarItems.map((item, key) => (
            <SidebarItem
              key={key}
              onClick={
                item.onClick ||
                (() => {
                  const externalLinkUrl = item.externalLink && t(item.externalLink)
                  if (externalLinkUrl) {
                    window.open(externalLinkUrl, '_blank')
                  }
                })
              }
            >
              <p>{t(item.label)}</p>
              <img src='/chevron.svg' alt='arrow' />
            </SidebarItem>
          ))}
        </SidebarList>

        <SidebarFooter>
          {!isChangeLanguageDisabled && (
            <OptionGroup
              dataTestId='language'
              style={{
                wrapper: {
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                },
                item: {
                  maxWidth: 76,
                  minWidth: 64,
                },
              }}
              onChange={(lang) => changeLanguage(`${lang}`, i18n.language)}
              options={getLanguageOptions({ appConfig, configuration }).map((i) => ({
                value: i.value,
                label: (i.value as unknown as string).toLocaleUpperCase(),
                isLabelTranslated: true,
              }))}
              value={i18n.language === 'cimode' ? UserLanguage.DEV : i18n.language}
            />
          )}
        </SidebarFooter>
      </SidebarWrapper>
    </>
  )
}

const appear = keyframes`
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
`

const OpacityScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  animation: ${appear} 0.3s ease;
`

const SidebarWrapper = styled.div<{ $open: boolean }>`
  background: white;
  bottom: 0;
  box-shadow: 0px 4px 32px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: -48px;
  top: 0;
  transform: ${(props) => (props.$open ? 'translateX(-48px)' : `translateX(${312 + 48}px)`)};
  transition: all ease 0.7s;
  width: 320px;
  z-index: 100;

  @media (max-height: 490px) {
    overflow-y: scroll;
  }
`

const SidebarList = styled.ul`
  margin: 0;
  padding: 0;
`

const SidebarItem = styled.li`
  align-items: center;
  border-bottom: 1px solid var(--color-form-field-border);
  cursor: pointer;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0 24px;
  transition: border 0.1s;

  :hover {
    p {
      color: var(--color-input);
    }
    img {
      opacity: 1;
    }
  }

  p {
    color: var(--color-secondary);
    transition: color 0.2s;
  }

  img {
    width: 8px;
    opacity: 0.6;
    transition: opacity 0.2s;
  }
`

const SidebarHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid var(--color-form-field-border);
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 16px 0 24px;
`
const SidebarFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px;

  @media (min-height: 490px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const SidebarCloseIcon = styled.div`
  align-items: center;
  border-radius: 18px;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  opacity: 0.6;
  transition:
    opacity 0.2s,
    border 0.5s;
  width: 36px;

  :hover {
    border: 1px solid var(--color-form-field-border-focus);
    opacity: 1;
  }

  img {
    width: 20px;
    height: 20px;
  }
`

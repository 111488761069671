import { ReactNode } from 'react'
import { ToastContainer, toast } from 'react-toastify'

export const Notification = {
  error: toast.error,
  info: toast.info,
  success: toast.success,
  warning: toast.warning,
}

export const NotificationProvider = () => {
  return <ToastContainer limit={1} position='top-center' />
}

import { usePlatform } from 'frr-web/lib/hooks/usePlatform'
import { configureBaseStyle } from 'frr-web/lib/theme/configureBaseStyle'
import { ComponentThemeContext, configureComponentTheme } from 'frr-web/lib/theme/theme.components'
import { FormThemeContext, configureFormTheme } from 'frr-web/lib/theme/theme.form'
import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppThemeContext, configureAppTheme } from '../../shared/theme/app.theme'
import { baseStyle } from '../../shared/theme/baseStyle'
import { configureModuleTheme, ModuleThemeContext } from '../../shared/theme/module.theme'
import { useProduct } from '../context/PartnerProduct.context'

// Create getter and setter for CSS variable value
export const getCSSVariable = (varName: string) => {
  const root = document.querySelector(':root') as HTMLElement
  const rs = getComputedStyle(root)
  return rs.getPropertyValue(varName)
}
export const setCSSVariable = (varName: string, value: string) => {
  const root = document.querySelector(':root') as HTMLElement
  if (root) {
    root.style.setProperty(varName, value)
  }
}

const setViewportHeight = () => {
  setCSSVariable('--view-height', window.innerHeight + 'px')
}

// Display Inline Context Provider
const DisplayInlineContext = createContext(false)
export const useDisplayInline = () => useContext(DisplayInlineContext)

// Theme Provider
export const ThemeProvider = (props: { children: ReactNode }) => {
  const { configuration } = useProduct()
  const [searchParams] = useSearchParams()
  const display = searchParams.get('display')
  const { isDesktop } = usePlatform()

  useLayoutEffect(() => {
    setViewportHeight()
  }, [])

  useEffect(() => {
    if (isDesktop) {
      window.addEventListener('resize', setViewportHeight)
    }
    return () => {
      isDesktop && window.removeEventListener('resize', setViewportHeight)
    }
  }, [isDesktop])

  const [BaseStyle] = useState(
    configureBaseStyle({
      baseStyle,
      brandBaseStyle: configuration.baseStyle,
      isStyleConfigActive: ['bob', 'dashboard'].includes(configuration.brandName),
      styleConfig: configuration.styleConfig || {},
    }) as any,
  )

  return (
    <DisplayInlineContext.Provider value={display === 'inline'}>
      <ComponentThemeContext.Provider value={configureComponentTheme(configuration.componentTheme)}>
        <BaseStyle />
        <AppThemeContext.Provider value={configureAppTheme(configuration.appTheme)}>
          <ModuleThemeContext.Provider value={configureModuleTheme(configuration.moduleTheme)}>
            <FormThemeContext.Provider value={configureFormTheme(configuration.formTheme)}>
              {props.children}
            </FormThemeContext.Provider>
          </ModuleThemeContext.Provider>
        </AppThemeContext.Provider>
      </ComponentThemeContext.Provider>
    </DisplayInlineContext.Provider>
  )
}

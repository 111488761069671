import { useEffect, useState } from 'react'
import { useAppConfig } from '../providers/AppConfigData.provider'

export const useHostname = () => {
  const { appConfig } = useAppConfig()
  const [urlHostname, setHostname] = useState(
    appConfig.app.whitelabelConfigApiUrl?.includes(window.location.hostname)
      ? undefined
      : window.location.hostname,
  )

  useEffect(() => {
    setHostname(
      appConfig.app.whitelabelConfigApiUrl?.includes(window.location.hostname)
        ? undefined
        : window.location.hostname,
    )
  }, [window.location.hostname, appConfig.app.whitelabelConfigApiUrl])

  return { urlHostname }
}

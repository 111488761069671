import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { ModuleSystem } from '../../engines/credit/external/creditExternal.types'
import {
  GTMClientData,
  GTM_Events,
  GTM_TrackApplicationEventData,
  GTM_TrackPageViewData,
  TrackEventData,
} from '../../types/analytics.types'
import { ProductSettingsT } from '../../types/financing.types'
import { useProduct } from './PartnerProduct.context'
import { AppConfig } from '../../types/app.types'

const GTM_EVENT_TRACKER = 'dataLayer'

/*
 * Prepare page view data
 */

const getModuleSystemEvents = (
  params: TrackEventData,
): Array<GTM_TrackPageViewData | GTM_TrackApplicationEventData> => {
  const events: Array<GTM_TrackPageViewData | GTM_TrackApplicationEventData> = []

  switch (params.moduleSystemInfo?.moduleSystemId) {
    case ModuleSystem.Calculator:
      events.push({
        event: GTM_Events.VirtualPageView,
        virtualPageTitle: 'Application Step 1 - Desired Loan',
        virtualPageUrl: '/application/step-1',
        applicationLanguage: params.language,
      })
      break
    case ModuleSystem.ContactInformation:
      events.push({
        event: GTM_Events.VirtualPageView,
        virtualPageTitle: 'Application Step 2 - Personal Data',
        virtualPageUrl: '/application/step-2',
        applicationAmount: params.finObj?.financingInfo.amount,
        applicationCurrency: 'CHF',
        applicationDuration: params.finObj?.financingInfo.duration,
        applicationInsurance: params.finObj?.financingInfo.hasPPI ? 1 : 0,
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
      })
      break
    case ModuleSystem.Income:
      events.push({
        event: GTM_Events.VirtualPageView,
        virtualPageTitle: 'Application Step 3 - Income Data',
        virtualPageUrl: '/application/step-3',
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
      })
      break
    case ModuleSystem.Expenses:
      events.push({
        event: GTM_Events.VirtualPageView,
        virtualPageTitle: 'Application Step 4 - Expenses Data',
        virtualPageUrl: '/application/step-4',
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
      })
      break
    case ModuleSystem.Review:
      events.push({
        event: GTM_Events.VirtualPageView,
        virtualPageTitle: 'Application Step 5 - Data Validation',
        virtualPageUrl: '/application/step-5',
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
      })
      break
    case ModuleSystem.DFSLoading:
      events.push({
        event: GTM_Events.VirtualPageView,
        virtualPageTitle: 'Application Scoring1',
        virtualPageUrl: '/application/step-5',
        applicationAmount: params.finObj?.financingInfo.amount,
        applicationCurrency: 'CHF',
        applicationDuration: params.finObj?.financingInfo.duration,
        applicationInsurance: params.finObj?.financingInfo.hasPPI ? 1 : 0,
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
      })
      events.push({
        event: GTM_Events.ApplicationSubmitted,
        applicationAmount: params.finObj?.financingInfo.amount,
        applicationCurrency: 'CHF',
        applicationDuration: params.finObj?.financingInfo.duration,
        applicationInsurance: params.finObj?.financingInfo.hasPPI ? 1 : 0,
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
      })
      break
    case ModuleSystem.Confirmation:
      events.push({
        event: GTM_Events.VirtualPageView,
        virtualPageTitle: 'Application Step 6 – Loan Offer (Result Okay)',
        virtualPageUrl: '/application/result',
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
      })
      events.push({
        event: GTM_Events.ApplicationSuccess,
        applicationAmount: params.finObj?.financingInfo.amount,
        applicationCurrency: 'CHF',
        applicationDuration: params.finObj?.financingInfo.duration,
        applicationInsurance: params.finObj?.financingInfo.hasPPI ? 1 : 0,
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
      })
      break
    case ModuleSystem.Rejection:
      events.push({
        event: GTM_Events.VirtualPageView,
        virtualPageTitle: 'Application Step 6 - Rejection',
        virtualPageUrl: '/application/rejection',
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
        // applicationErrorCode: params.finObj?.???,
      })
      events.push({
        event: GTM_Events.ApplicationRejected,
        applicationAmount: params.finObj?.financingInfo.amount,
        applicationCurrency: 'CHF',
        applicationDuration: params.finObj?.financingInfo.duration,
        applicationInsurance: params.finObj?.financingInfo.hasPPI ? 1 : 0,
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
      })
      break
    case ModuleSystem.Error:
      events.push({
        event: GTM_Events.VirtualPageView,
        virtualPageTitle: 'Application Error - Technical Error',
        virtualPageUrl: '/application/error',
        applicationId: params.finObj?.contextInfo.financingId,
        applicationLanguage: params.language,
        // applicationErrorCode: ???
      })
      break
  }

  return events
}

/*
 * Tracker for redux sagas
 */

export const getGoogleTagManager = (appConfig: AppConfig) => ({
  trackPageView: (params: TrackEventData) => {
    const tracker = (window as any)[GTM_EVENT_TRACKER]
    const events = getModuleSystemEvents(params)

    if (tracker) {
      events.forEach((event) => {
        if (appConfig.app.whitelabelEnvironment === 'dev') {
          console.debug('### GTM EVENT - TRACK EVENT ###')
          console.debug(JSON.stringify(event, null, 2))
          console.debug('')
        } else {
          tracker.push(event)
        }
      })
    }
  },
})

/*
 * Goolge Tag Manager script that ordinarily should be pasted into the HEAD section of the index.html
 */

/* Original Script
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5XT8F35');</script>
<!-- End Google Tag Manager -->
*/

const loadGTMTrackerHeadScript = (w: any, d: any, s: string, l: string, i: string) => {
  w[l] = w[l] || []
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })
  const f = d.getElementById(s),
    j = d.createElement('script'),
    dl = l !== GTM_EVENT_TRACKER ? '&l=' + l : ''
  j.async = true
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
  f.parentNode.insertBefore(j, f)
}

const loadGTMTrackerBodyScript = (doc: any, id: string) => {
  const gtmElement = doc.getElementById('gtm-no-script')
  const iframe = doc.createElement('iframe')
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`
  iframe.width = 0
  iframe.height = 0
  iframe.style = 'display:none;visibility:hidden'

  gtmElement.appendChild(iframe)
}

const loadGTMTracker = (trackerId: string | undefined, onLoaded: () => any) => {
  if (trackerId) {
    loadGTMTrackerHeadScript(window, document, 'gtm-script-hook', GTM_EVENT_TRACKER, trackerId)
    loadGTMTrackerBodyScript(document, trackerId)
  }

  onLoaded()
}

/*
 * Provider which loads the Google Tag Manager scripts based on the ui settings
 */

type GTMTracker = {
  getClientData: () => GTMClientData
  setPartnerConfig: (params: { appConfig: AppConfig; product: ProductSettingsT }) => void
}

const GTMTrackerIdContext = createContext<string | null>(null)
export const useGTMTracker = (): GTMTracker => {
  const trackerId = useContext(GTMTrackerIdContext)

  return {
    getClientData: (): {} => {
      const tracker: { get: (key: string) => any } | undefined =
        trackerId &&
        (window as any).google_tag_manager &&
        (window as any).google_tag_manager?.[trackerId]?.[GTM_EVENT_TRACKER]

      let clientData: GTMClientData = (tracker?.get('gtagApiResult') as GTMClientData) || {}

      return clientData
    },

    setPartnerConfig: (params) => {
      const tracker = (window as any)[GTM_EVENT_TRACKER]

      if (tracker) {
        const partnerConfig = {
          event: GTM_Events.Configuration,
          partnerId: params.product.variantId,
          environment: params.appConfig.app.whitelabelEnvironment,
          onboardingChannel: params.product.onboardingChannel,
        }

        if (params.appConfig.app.whitelabelEnvironment === 'dev') {
          console.debug('### GTM EVENT - SET PARTNER CONFIG ###')
          console.debug(JSON.stringify(partnerConfig, null, 2))
          console.debug('')
        } else {
          tracker.push(partnerConfig)
        }
      }
    },
  }
}

export const GoogleTagManagerProvider = (props: { children: ReactNode }) => {
  const { configuration } = useProduct()

  const { bobGTMTrackerId } = configuration.trackingConfig || {}

  /*
   * Load Bob GTM tracker
   */

  const [isGTMTrackerLoaded, setGTMTrackerLoaded] = useState(false)
  useEffect(() => {
    if (!isGTMTrackerLoaded) loadGTMTracker(bobGTMTrackerId, () => setGTMTrackerLoaded(true))
  }, [isGTMTrackerLoaded, bobGTMTrackerId])

  const trackerId = (isGTMTrackerLoaded && bobGTMTrackerId) || null

  return <GTMTrackerIdContext.Provider value={trackerId}>{props.children}</GTMTrackerIdContext.Provider>
}

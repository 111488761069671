import { DeprecatedLanguage as Language } from 'frr-web/lib/theme/language'

const DEFAULT_LANGUAGE = Language.DE
const ALL_LANGUAGES = [Language.DE, Language.FR, Language.IT, Language.EN]
const isValid = (l: Language) => ALL_LANGUAGES.includes(l)
const parseLocale = (str: string): Language => str.split('-')[0] as Language

export const useUserLanguage = (): Language => {
  const navigator = window.navigator as any
  const navLang1 = navigator.userLanguage as Language | undefined
  const navLang2 = navigator.language as Language | undefined

  let language: Language = DEFAULT_LANGUAGE

  if (navLang1 && isValid(parseLocale(navLang1))) {
    language = parseLocale(navLang1)
  } else if (navLang2 && isValid(parseLocale(navLang2))) {
    language = parseLocale(navLang2)
  }

  return language
}

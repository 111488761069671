import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { PartnerFrontendConfiguration, UserLanguage } from '../../types/frontend.types'
import { ProductSettingsT } from '../../types/financing.types'

type PartnerProduct = {
  product: ProductSettingsT
  configuration: PartnerFrontendConfiguration
}

export type AppState = {
  isPartnerLoginRedirect: boolean
  isPartnerLoginCallback: boolean
  language: UserLanguage | null
  partnerProduct: PartnerProduct | null
  partnerLoginCallbackUrl: string | null
}

const initialState: AppState = {
  isPartnerLoginRedirect: false,
  isPartnerLoginCallback: false,
  language: null,
  partnerLoginCallbackUrl: null,
  partnerProduct: null,
}

/*
 * State slice defined by the name with reducers and the initial state
 * (see https://redux-toolkit.js.org/api/createSlice)
 */

export const appState = createSlice({
  initialState,
  name: 'app',
  reducers: {
    setIsPartnerLoginRedirect: (state, action: PayloadAction<boolean>) => {
      state.isPartnerLoginRedirect = action.payload
    },
    setIsPartnerLoginCallback: (state, action: PayloadAction<boolean>) => {
      state.isPartnerLoginCallback = action.payload
    },
    setLanguage: (state, action: PayloadAction<UserLanguage | null>) => {
      state.language = action.payload
    },
    setPartnerProduct: (state, action: PayloadAction<PartnerProduct>) => {
      state.partnerProduct = action.payload
    },
    setPartnerCallbackUrl: (state, action: PayloadAction<string>) => {
      state.partnerLoginCallbackUrl = action.payload
    },
  },
})

/*
 * Actions
 */

export const appActions = (dispatch: Dispatch) => ({
  setIsPartnerLoginRedirect: (value: boolean) =>
    dispatch(appState.actions.setIsPartnerLoginRedirect(value)),
  setIsPartnerLoginCallback: (value: boolean) =>
    dispatch(appState.actions.setIsPartnerLoginCallback(value)),
  setLanguage: (value: UserLanguage | null) => dispatch(appState.actions.setLanguage(value)),
  setPartnerProduct: (values: PartnerProduct) => dispatch(appState.actions.setPartnerProduct(values)),
  setPartnerCallbackUrl: (value: string) => dispatch(appState.actions.setPartnerCallbackUrl(value)),
})

/*
 * Selectors
 */

type State = { app: AppState }

export const appSelectors = {
  appState: (state: State) => state.app,
  isPartnerLoginRedirect: (state: State) => state.app.isPartnerLoginRedirect,
  isPartnerLoginCallback: (state: State) => state.app.isPartnerLoginCallback,
  language: (state: State) => state.app.language,
  partnerProduct: (state: State) => state.app.partnerProduct,
  partnerLoginCallbackUrl: (state: State) => state.app.partnerLoginCallbackUrl,
}

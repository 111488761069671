import jwt_decode from 'jwt-decode'
import { UserType } from '../types/frontend.types'
import { PartnerInfo } from '../types/partner.types'
import { FinancingSessionStorage } from '../view/providers/FinancingSession.provider'
import { dummyOnboardingPartnerInfo } from './assets/dev/dummyOnboardingPartnerInfo'

// ------------------------------------------------------------
// Default module system
// ------------------------------------------------------------

export const getDefaultModuleSystem = () => undefined
// import.meta.env.VITE_DEV_USE_DEFAULT_MODULE_SYSTEM || import.meta.env.VITE_DEV_DEFAULT_MODULE_SYSTEM

// ------------------------------------------------------------
// Partner & products
// ------------------------------------------------------------

export const getDevPartner = () => undefined // import.meta.env.VITE_DEV_PARTNER

// ------------------------------------------------------------
// Partner onboarding
// ------------------------------------------------------------

const useDummyPartner = false // import.meta.env.VITE_DEV_USE_DUMMY_PARTNER_INFO === 'true'
export const getPartnerInfoData = (): PartnerInfo =>
  useDummyPartner
    ? dummyOnboardingPartnerInfo
    : {
        company: {
          legalName: '',
          legalForm: '',
          uid: '',
          address: {
            street: '',
            houseNo: null,
            postalCode: '',
            city: '',
            country: '',
          },
          phone: '',
          email: '',
          foundingDate: null,
          companyURL: null,
          industry: null,
          expectedAmountPerFinancing: null,
          expectedNumberOfFinancingsPerAnnum: null,
          expectedVolumePerAnnum: null,
          targetLaunchDate: null,
          turnoverPerAnnum: null,
          legitObjectOfCompany: null,
          capital: null,
        },
        bankAccount: {
          iban: '',
          bankName: null,
          street: null,
          houseNo: null,
          postalCode: null,
          city: null,
          country: null,
        },
        contactPersons: [
          {
            gender: null,
            firstName: '',
            lastname: '',
            phone: '',
            email: '',
            address: {
              street: '',
              postalCode: '',
              city: '',
              country: '',
            },
            role: '',
            preferredLanguage: '',
            hasSigningAuthority: null,
            dateOfBirth: '',
            shareOfCompany: null,
          },
        ],
        numberOfContactPersons: 1,
        meta: {},
      }

// ------------------------------------------------------------
// Extracted User type and return url from session token
// ------------------------------------------------------------

export const getUserTypeFromSessionWithEnvVars = (): UserType => {
  const search = new URLSearchParams(window.location.search)

  // if no session is avaialable in session storage, try to get it from query params of the email verification link
  const token = FinancingSessionStorage.getSession() || search.get('financing_session_token')

  // Overwrite user type with query param
  const searchUserType = search.get('user_type')
  if (Object.values(UserType).includes((searchUserType || '') as UserType)) {
    return searchUserType as UserType
  }

  // if (import.meta.env.VITE_DEV_USER_TYPE) {
  //   return import.meta.env.VITE_DEV_USER_TYPE as UserType
  // }

  if (token && token > '') {
    const decoded = jwt_decode(token) as {
      user_type: UserType
    }
    return decoded.user_type
  }

  return UserType.CustomerVerification
}

// ------------------------------------------------------------
// Poll function
// ------------------------------------------------------------

export const pollWithEnvVars = {
  func: (params: { getFinObj: () => void }) => {
    // if (
    //   import.meta.env.VITE_DEV_USE_DEFAULT_MODULE_SYSTEM !== 'true' &&
    //   import.meta.env.VITE_DEV_DEFAULT_MODULE_SYSTEM !== 'true'
    // ) {
    params.getFinObj()
    // }
  },
  interval: 5000,
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BobBaseApp_OnboardingChannelEnum {
    UNKNOWN = 'UNKNOWN',
    WEBSHOP = 'WEBSHOP',
    ZER000 = 'ZER000',
    PAYREXX = 'PAYREXX',
    BROKER = 'BROKER',
    BROKER_API = 'BROKER_API',
    CREDIT = 'CREDIT',
    POSTFINANCE = 'POSTFINANCE',
    PARTNER_RETAIL = 'PARTNER_RETAIL',
    RETAIL = 'RETAIL',
    BPL_RETAIL = 'BPL_RETAIL',
    BPL_ECOMMERCE = 'BPL_ECOMMERCE',
    BPL_API = 'BPL_API',
    POS = 'POS',
    ECOMMERCE = 'ECOMMERCE',
}

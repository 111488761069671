import { BrandTheme } from '../../types/frontend.types'
import { componentTheme } from './bob.component.theme'
import { formTheme } from './bob.form.theme'
import { appTheme } from './bob.app.theme'
import { moduleTheme } from './bob.module.theme'
import { baseStyle } from './bob.baseStyle'

export const brand: BrandTheme = {
  appTheme,
  baseStyle,
  brandName: 'bob',
  componentTheme,
  formTheme,
  moduleTheme,
}

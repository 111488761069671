import { Button, ButtonType } from 'frr-web/lib/components/Button'
import { useInlineStyle, useAppTheme } from '../../../shared/theme/app.theme'
import { ModuleFooterProps } from '../../../types/frontend.types'
import { Div } from 'frr-web/lib/html'

export const ModuleFooter = (props: ModuleFooterProps) => {
  const theme = useAppTheme()
  const getStyle = useInlineStyle(theme, 'moduleFooter')(props.style)

  return (
    <Div {...getStyle('outerWrapper')}>
      <Div {...getStyle('innerWrapper')}>
        {props.previous && (
          <Button
            onClick={() => {
              if (props.previous) {
                props.previous()
              }
            }}
            label={props.previousLabel || 'back'}
            override={{ marginRight: 16 }}
          />
        )}
        <Button
          onClick={() => props.next()}
          label={props.nextLabel || 'next'}
          type={ButtonType.Primary}
        />
      </Div>
    </Div>
  )
}

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const relativeTimeFromNow = (date: string | Date | undefined) => dayjs(date).fromNow()

export enum DurationUnit {
  Days = 'day',
  Hours = 'hour',
  Minutes = 'minute',
  Seconds = 'second',
  Milliseconds = 'millisecond',
}
export const duration = (params: {
  startDate?: string | Date | null
  endDate?: string | Date | null
  unit: DurationUnit
}) => {
  let duration = undefined
  if (params.startDate && params.endDate) {
    const endDate = dayjs(params.endDate)
    duration = endDate.diff(params.startDate, params.unit)
  }
  return duration
}

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TrackEventType } from '../../types/analytics.types'
import { UserLanguage } from '../../types/frontend.types'
import { useLanguage } from '../context/Language.context'
import { useProduct } from '../context/PartnerProduct.context'
import { useTrackEvent } from '../hooks/useTrackEvent'
import { CookieModal } from '../scaffold/CookieBot'

// eslint-disable-next-line react/display-name
export const CookieModalMemo = React.memo<{
  domainGroupId: string
  language: UserLanguage
}>(
  (props) => CookieModal(props),
  () => true,
)

export const AcceptCookieProvider = () => {
  const { configuration } = useProduct()
  const { language } = useLanguage()
  const navigate = useNavigate()

  const localLanguage = localStorage.getItem('language')

  const isCookieBotActive = !!configuration.trackingConfig?.cookieBotDomainGroupId && !!language

  // Tracking
  const trackEvent = useTrackEvent('AcceptCookieProvider')

  useEffect(() => {
    if (isCookieBotActive) {
      if (String(language) !== localLanguage) {
        if (!document.cookie.includes('CookieConsent')) {
          localStorage.setItem('language', String(language))
          navigate(0)
        }
      } else if (localLanguage) {
        trackEvent({
          type: TrackEventType.ReloadCookieBot,
          hasReload: false,
        })
      }
    }
  }, [language, isCookieBotActive])

  return isCookieBotActive ? (
    <CookieModalMemo
      domainGroupId={configuration.trackingConfig!.cookieBotDomainGroupId!}
      language={language as unknown as UserLanguage}
    />
  ) : null
}

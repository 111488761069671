import { ReactNode, useEffect, useState } from 'react'
import { Loading } from '../scaffold/Loading'
import { useUserLanguage } from '../scaffold/translation/useUserLanguage'
import { getCookie, removeCookie, setCookie } from '../../shared/helpers'

export const FinancingSessionStorage = {
  getSession: () => window.sessionStorage.getItem('financing.session') ?? null,
  persistSession: () => {
    const session = FinancingSessionStorage.getSession()
    if (session) {
      setCookie({ name: 'financing.session', value: session, expires: { minutes: 30 } })
    }
  },
  removeSession: () => window.sessionStorage.removeItem('financing.session'),
  saveSession: (session: string) => window.sessionStorage.setItem('financing.session', session),
  reactivatePersistedSession: () => {
    const cookieSession = getCookie({ name: 'financing.session' })

    if (cookieSession) {
      FinancingSessionStorage.saveSession(cookieSession)
    }

    FinancingSessionStorage.clearPersistedSession()
  },
  clearPersistedSession: () => {
    removeCookie({ name: 'financing.session' })
  },
}

export const FinancingSessionProvider = (props: { children: ReactNode }) => {
  const location = window.location
  const [loading, setLoading] = useState(true)
  const language = useUserLanguage()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    let session = params.get('session')

    if (!session) {
      session = FinancingSessionStorage.getSession()

      if (session) {
        FinancingSessionStorage.clearPersistedSession()
      }
    } else {
      // Push new url (outside of the context of ReactRouter)
      const url = new URL(window.location as any)
      let newUrl =
        (params.get('language') && `/${params.get('language')}`) ||
        (url.pathname !== '/' && url.pathname) ||
        `/${language}`

      params.delete('language')
      params.delete('session')
      const newSearch = params.toString()
      newUrl = newSearch > '' ? `${newUrl}?${newSearch}` : newUrl
      window.history.pushState({}, '', newUrl)
    }

    if (session) {
      FinancingSessionStorage.saveSession(session)
    }

    setLoading(false)
  }, [location.pathname, location.search])

  return loading ? <Loading label='creating session' /> : <>{props.children}</>
}

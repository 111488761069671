import { PlatformDataT, usePlatform } from 'frr-web/lib/hooks/usePlatform'
import { StateLevels } from '../../shared/helpers.stateLevel'
import { TrackEvent, TrackEventData, TrackEventType, Tracker } from '../../types/analytics.types'
import { FinancingObject, ProductSettingsT } from '../../types/financing.types'
import { useOptionalFinObj, useStateLevels } from '../context/Data.context'
import { getGoogleTagManager } from '../context/GoogleTagManager.context'
import { useModuleSystemOptional } from '../context/ModuleSystem.context'
import { useProduct } from '../context/PartnerProduct.context'
import { useAppConfig } from '../providers/AppConfigData.provider'
import { useTracker } from '../context/Tracker.context'
import { AppConfig } from '../../types/app.types'

const trackEvent = (params: {
  appConfig: AppConfig
  finObj?: FinancingObject
  moduleSystem: {
    id?: string
    type?: string
    moduleState?: string
  }
  platformData: PlatformDataT
  product: ProductSettingsT
  stateLevels?: StateLevels
  tracker: Tracker
}) => {
  const googleTagManager = getGoogleTagManager(params.appConfig)

  return (event: TrackEvent) => {
    const { finObj, moduleSystem, product, stateLevels, tracker } = params
    if (event.type === TrackEventType.Exception) {
      tracker.trackException(event)
    } else {
      if (product) {
        const moduleSystemInfo = moduleSystem
          ? {
              moduleType: moduleSystem.type,
              moduleState: moduleSystem.moduleState,
              moduleSystemId: moduleSystem.id,
            }
          : undefined

        const commonTrackEventData: TrackEventData = {
          finObj,
          moduleSystemInfo,
          platformData: params.platformData,
          product,
          stateLevels,
        }

        if (event.type === TrackEventType.ModuleView) {
          /*
           * Track module view (PageView)
           */

          const trackEventData: TrackEventData = {
            ...commonTrackEventData,
            language: event.language,
          }

          if (moduleSystemInfo) {
            tracker.trackPageView(trackEventData)
            googleTagManager.trackPageView(trackEventData)
          } else {
            tracker.trackEvent({
              name: event.type,
              ...trackEventData,
            })
          }
        } else if (event.type === TrackEventType.ChangeLanguage) {
          /*
           * Track user action: change language
           */

          tracker.trackEvent({
            name: event.type,
            ...commonTrackEventData,
            language: event.newLanguage,
            additionalData: { 'Old language': event.oldLanguage },
          })
        } else if (event.type === TrackEventType.BplVerifyIDCompleted) {
          /*
           * Track BPL specific user action: bob ID service (CRIF phyon) did complete
           */

          tracker.trackEvent({
            name: event.type,
            ...commonTrackEventData,
            additionalData: {
              'BPL verify ID: status': event.status,
            },
          })
        } else if (event.type === TrackEventType.BplParnterResendSMS) {
          /*
           * Track BPL specific user action: Resend SMS to customer for ID verification
           */

          tracker.trackEvent({
            name: event.type,
            ...commonTrackEventData,
            additionalData: {
              'BPL resend SMS: has number changed': event.hasChanged,
            },
          })
        } else if (event.type === TrackEventType.BplVerifyIDRetry) {
          /*
           * Track BPL specific user action: change document type (not fired on initial select)
           */

          tracker.trackEvent({
            name: event.type,
            ...commonTrackEventData,
            additionalData: {
              'BPL verify ID: status': event.isCompleted ? 'Retry' : 'Ongoing',
            },
          })
        } else if (event.type === TrackEventType.BplVerifyIDChangeDocumentType) {
          /*
           * Track BPL specific user action: change document type (not fired on initial select)
           */

          tracker.trackEvent({
            name: event.type,
            ...commonTrackEventData,
            additionalData: {
              'BPL verify ID: document type': event.newDocumentType,
              'BPL verify ID: old document type': event.oldDocumentType,
            },
          })
        } else if (event.type === TrackEventType.BplVerifyEmailResendEmail) {
          /*
           * Track BPL specific user action: resend email to customer
           */

          tracker.trackEvent({
            name: event.type,
            ...commonTrackEventData,
            additionalData: {
              'BPL resend email: has email changed': event.hasChanged,
            },
          })
        } else if (event.type === TrackEventType.BplVerifyIDUnsupportedPlatform) {
          /*
           * Track BPL specific user action: platform (device, browser or OS) is not supported for ID verification flow
           */

          tracker.trackEvent({
            name: event.type,
            ...commonTrackEventData,
            additionalData: {
              'BPL verify ID: error': event.platformState,
              'BPL verify ID: is unsupported error ignored': event.isIgnored,
            },
          })
        } else if (event.type === TrackEventType.SubmitForm) {
          /*
           * Track submit form action
           */

          tracker.trackEvent({
            name: event.type,
            ...commonTrackEventData,
            additionalData: event.data,
          })
        } else {
          /*
           * Track any other user action
           */

          tracker.trackEvent({
            name: event.type,
            ...commonTrackEventData,
          })
        }
      }
    }
  }
}

export const useTrackEvent = (trackerOriginId?: string) => {
  const { appConfig } = useAppConfig()
  const { tracker } = useTracker()
  const finObj = useOptionalFinObj()
  const { moduleSystem, moduleConfig, moduleState } = useModuleSystemOptional()
  const { product } = useProduct()
  const { stateLevels } = useStateLevels()
  const platformData = usePlatform()

  const mappedModuleSystem = moduleSystem
    ? {
        id: moduleSystem.id ? `${moduleSystem.id}` : undefined,
        type: moduleConfig?.type,
        moduleState: moduleState,
      }
    : {}

  return (event: TrackEvent) => {
    if (trackerOriginId && appConfig.app.whitelabelEnvironment !== 'prod') {
      console.debug('TRACKER ORIGIN ID', trackerOriginId)
    }

    trackEvent({
      appConfig,
      finObj,
      moduleSystem: mappedModuleSystem,
      platformData,
      product,
      stateLevels,
      tracker,
    })(event)
  }
}

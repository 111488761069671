import { createStyled } from 'frr-web/lib/theme/util'
import React, { Suspense, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useAppTheme, useCSSStyles } from '../../../shared/theme/app.theme'
import { useGTMTracker } from '../../context/GoogleTagManager.context'
import { useModuleSystem } from '../../context/ModuleSystem.context'
import { useProduct } from '../../context/PartnerProduct.context'
import { Footer } from '../../scaffold/footer/Footer'
import { Header } from '../../scaffold/header/Header'
import { ModuleFooter } from './ModuleFooter'
import { ModuleHeader } from './ModuleHeader'
import { useDisplayInline } from '../../providers/Theme.provider'
import { useAppConfig } from '../../providers/AppConfigData.provider'
import { Div } from 'frr-web/lib/html'

// Dynamic imports
const ModuleLoader = React.lazy(() => import('./ModuleLoader'))

export const ModuleDelegator = () => {
  const { appConfig } = useAppConfig()
  const { product } = useProduct()
  const { moduleConfig, moduleState, moduleSystem } = useModuleSystem()
  const tracker = useGTMTracker()
  const theme = useAppTheme()
  const getCSSStyles = useCSSStyles(theme, 'moduleDelegator')({})
  const isDisplayInline = useDisplayInline()

  useEffect(() => {
    tracker.setPartnerConfig({ appConfig, product })
  }, [])

  return (
    <FadeInWrapper>
      {!isDisplayInline && <Header />}

      <ModuleHeader />

      <Div {...getCSSStyles('wrapper')} dataTestId='delegator' id='delegator'>
        <Suspense fallback={<div />}>
          <ModuleLoader
            moduleConfig={moduleConfig}
            moduleState={moduleState}
            moduleSystem={moduleSystem.id as string}
          />
        </Suspense>

        {moduleConfig?.moduleFooter && <ModuleFooter {...moduleConfig?.moduleFooter} />}
      </Div>

      {!moduleConfig?.hideFooter && <Footer />}
    </FadeInWrapper>
  )
}

const FadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1.0
  }
`
const FadeInWrapper = styled.div`
  animation: ${FadeInAnimation} 2s ease;
`

import { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FinancingSessionStorage } from '../providers/FinancingSession.provider'
import { appActions, appSelectors } from '../../data/redux/appState.redux'

export const PartnerLoginCallbackProvider = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const partnerLoginCallbackUrl = useSelector(appSelectors.partnerLoginCallbackUrl)

  const lang = searchParams.get('state')

  useEffect(() => {
    // Load financing session
    FinancingSessionStorage.reactivatePersistedSession()

    // Set partner login callback url
    appActions(dispatch).setPartnerCallbackUrl(window.location.href)
  }, [])

  useEffect(() => {
    if (partnerLoginCallbackUrl) {
      // Redirect to credit application route

      const newSearchParams = new URLSearchParams()
      if (searchParams.has('partner')) {
        newSearchParams.append('partner', searchParams.get('partner')!)
        navigate(`/${lang}?${newSearchParams.toString()}`)
      } else {
        navigate(`/${lang}`)
      }
    }
  }, [partnerLoginCallbackUrl, lang])

  return null
}

import { P } from 'frr-web/lib/html'
import { createStyled } from 'frr-web/lib/theme/util'
import React, { ReactNode } from 'react'
import { useCSSStyles, useAppTheme } from '../../../shared/theme/app.theme'

const Link = createStyled('a')

export const HeaderLink = ({
  linkUrl = '#',
  linkText,
  target = '_blank',
  children,
}: {
  linkUrl?: string | null
  linkText?: string | null
  target?: '_blank' | '_self'
  children?: ReactNode
}) => {
  const theme = useAppTheme()
  const getStyle = useCSSStyles(theme, 'header')()

  return (
    <Link href={linkUrl} target={target} {...getStyle('link')}>
      {linkText && <P label={linkText} {...getStyle('linkText')} />}
      {children}
    </Link>
  )
}

import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

// Generate a random token
const generateToken = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

export const MockPostFinanceLogin = () => {
  return (
    <Wrapper>
      <Container>
        <Logo
          src='https://stbobfspfrontendassets.blob.core.windows.net/bobzeroassets/pf-logo.png'
          alt='PostFinance'
        />
        <div style={{ padding: '0 48px 32px' }}>
          <h1 style={{ marginTop: 32, fontFamily: 'Arial' }}>This is a PostFinance login faker.</h1>
          <p style={{ fontFamily: 'Arial' }}>
            Just click on the button below to simulate a successful login. The click will redirect you
            back to the application using a mocked partner login callback URL.
          </p>

          <button
            data-test-id='simulate-successful-login-button'
            style={{
              fontFamily: 'Arial',
              cursor: 'pointer',
              width: '100%',
              height: 52,
              fontSize: 18,
              fontWeight: 'bold',
              marginTop: 64,
            }}
            onClick={() => {
              const loginUrl = new URL(window.location.href)
              const loginQueryParams = new URLSearchParams(loginUrl.search)
              const redirectURL = decodeURIComponent(loginQueryParams.get('redirect_uri')!)

              window.location.href = `${redirectURL}?code=${generateToken(32)}`
            }}
          >
            Simulate successful login
          </button>

          <button
            data-test-id='simulate-failed-login-button'
            style={{
              fontFamily: 'Arial',
              cursor: 'pointer',
              width: '100%',
              height: 52,
              fontSize: 18,
              fontWeight: 'bold',
              marginTop: 16,
            }}
            onClick={() => {
              const loginUrl = new URL(window.location.href)
              const loginQueryParams = new URLSearchParams(loginUrl.search)
              const redirectURL = decodeURIComponent(loginQueryParams.get('redirect_uri')!)

              window.location.href = `${redirectURL}?error=login-cancelled`
            }}
          >
            Simulate cancelled/failed login
          </button>
        </div>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #f2f2f2;
`

const Container = styled.div`
  width: 480px;
  background: #fff;
`

const Logo = styled.img`
  width: 100%;
  position: relative;
`

import { ReactNode, useEffect } from 'react'
import { useSetModuleSystem } from '../../../data/mutations/financingObject.mutations'

export const SessionError = (props: { children: ReactNode }) => {
  const setModuleSystem = useSetModuleSystem()

  useEffect(() => {
    setModuleSystem('SessionExpired')
  }, [])

  return <>{props.children}</>
}

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { appState } from './appState.redux'

const reducer = combineReducers({
  app: appState.reducer,
})

export const appStore = configureStore({
  reducer,
})

export enum ModuleSystem {
  Rejection = 'Rejection',
  Error = 'Error',
  ExpiredSMSLink = 'ExpiredSMSLink',
  ExpiredEmailLink = 'ExpiredEmailLink',
  Introduction = 'Introduction',
  InvalidSMSLink = 'InvalidSMSLink',
  CustomerIDVerificationData = 'CustomerIDVerificationData',
  CustomerIDVerification = 'CustomerIDVerification',
  CustomerIDVerificationRetry = 'CustomerIDVerificationRetry',
  PlanSummary = 'PlanSummary',
  VerifyEmail = 'VerifyEmail',
  CustomerConfirmation = 'CustomerConfirmation',
  CatchAllSystem = 'CatchAllSystem',
}

export enum ModuleSystemGroup {
  CustomerConfirmation = 'CustomerConfirmation',
  EmailVerification = 'EmailVerification',
  Error = 'Error',
  IDVerification = 'Verification',
  NoNavigation = 'NoNavigation',
  PlanConfirmation = 'PlanConfirmation',
}

import { Path } from 'frr-web/lib/util'
import get from 'lodash.get'
import { PatchUpdates } from '../data/api/api.types'
import {
  BobProductsApp_DTOs_ExternalView_StateLevelPropertiesDTO,
  BobProductsApp_DTOs_ExternalView_StateLevelTreeDTO,
} from '../data/api/openapi'
import {
  ActionIdenitifier,
  ActionType,
  ActionValueIdentifierType,
  ActionValueListItem,
  FinancingObject,
  StateLevel,
  StateLevelInfoActionType,
} from '../types/financing.types'

/*
 * State level list helper functions
 */

export type StateLevelItem = BobProductsApp_DTOs_ExternalView_StateLevelTreeDTO
export type StateLevelList = Array<StateLevelItem>
export type StateLevels = {
  activeStateLevel1: StateLevel | null
  activeStateLevel2: StateLevel | null
  activeStateLevel3: StateLevel | null
  stateList: StateLevelList
}
export type LevelInfo = BobProductsApp_DTOs_ExternalView_StateLevelPropertiesDTO

export const mapStateLevels = (params: { financing?: FinancingObject | null }): StateLevels => {
  const { financing } = params

  let stateList: StateLevelList = []
  let activeStates: Omit<StateLevels, 'stateList'> = {
    activeStateLevel1: null,
    activeStateLevel2: null,
    activeStateLevel3: null,
  }

  if (financing) {
    stateList = financing.stateList || []
    activeStates.activeStateLevel1 =
      financing.stateLevelInfo.activeStateLevel1 !== StateLevel.NONE
        ? (financing.stateLevelInfo.activeStateLevel1 as StateLevel)
        : null
    activeStates.activeStateLevel2 =
      financing.stateLevelInfo.activeStateLevel2 !== StateLevel.NONE
        ? (financing.stateLevelInfo.activeStateLevel2 as StateLevel)
        : null
    activeStates.activeStateLevel3 =
      financing.stateLevelInfo.activeStateLevel3 !== StateLevel.NONE
        ? (financing.stateLevelInfo.activeStateLevel3 as StateLevel)
        : null
  }

  return {
    ...activeStates,
    stateList,
  }
}

const StateLevelValues = Object.values(StateLevel)

export const checkIsActiveStateInLevelState = (params: {
  states: Array<StateLevel>
  stateLevels: StateLevels
}): boolean => {
  let isActiveState = false

  if (params.states.length && StateLevelValues.includes(params.states[0])) {
    isActiveState =
      (params.states as Array<StateLevel>).findIndex(
        (level) =>
          level === params.stateLevels.activeStateLevel1! ||
          level === params.stateLevels.activeStateLevel2! ||
          level === params.stateLevels.activeStateLevel3!,
      ) !== -1
  }

  return isActiveState
}

export type StateLevelInfo = { exists: boolean } & LevelInfo

export const findStateLevelInfo = (
  stateLevelList: StateLevelList,
  stateLevel: StateLevel,
): StateLevelInfo => {
  return stateLevelList.reduce(
    (acc, level) => {
      if (acc.exists) {
        return acc
      } else if (level.levelState === stateLevel) {
        return {
          ...level.levelInfo,
          exists: true,
        }
      } else {
        return findStateLevelInfo(level.childLevels || [], stateLevel)
      }
    },
    {
      exists: false,
      isActive: false,
      isCompleted: false,
      startedAt: null,
      completedAt: null,
    } as StateLevelInfo,
  )
}

/*
 * State level action helper functions
 */

export const hasStateLevelAction = (finObj: FinancingObject, actionIdentifier: ActionIdenitifier) =>
  finObj.stateLevelInfo.actionsForCustomer.findIndex(
    (action) => action.actionIdentifier === actionIdentifier,
  ) !== -1

export const getStateLevelAction = <Data extends {}>(params: {
  actionIdentifier: ActionIdenitifier
  finObj: FinancingObject
  data?: Data
  dataKeyMap?: Partial<Record<ActionValueIdentifierType, Path<Data>>>
}): StateLevelInfoActionType | null => {
  const data = params.data ?? params.finObj
  const action = params.finObj.stateLevelInfo.actionsForCustomer.find(
    (action) => action.actionIdentifier === params.actionIdentifier,
  )

  let actionType: StateLevelInfoActionType | null = null

  // Prepare updates for PATCH action
  if (action?.actionType.typeEnum === ActionType.PATCH) {
    // const { commandKeys } = action.actionType as any as {
    //   commandKeys: Array<{ path: string; operation: string }>
    // }

    let updates: PatchUpdates = []
    if (params.data && params.dataKeyMap) {
      const mappedValueList = (
        action.actionType as unknown as { typeEnum: ActionType; valueList: Array<ActionValueListItem> }
      ).valueList.map((valueItem) => ({
        op: valueItem.operation,
        path: valueItem.path,
        value: get(data, params.dataKeyMap?.[valueItem.valueIdentifier ?? ''] ?? ''),
      }))
      //   // Create updates list from command keys
      //   const dataKeyKeys = Object.keys(params.dataKeyMap)
      //   const commandKeysUpdates = commandKeys
      //     .map((commandKey) => {
      //       const dataKey = dataKeyKeys.find((dataKeyKey) => commandKey.path.includes(dataKeyKey))
      //       const value = get(data, params.dataKeyMap?.[dataKey ?? ''] ?? '')

      //       if (value !== undefined && ['boolean', 'string', 'number'].includes(typeof value)) {
      //         return {
      //           op: commandKey.operation,
      //           path: commandKey.path,
      //           value,
      //         }
      //       }
      //       return false
      //     })
      //     .filter(Boolean)
      //
      // updates = commandKeysUpdates as PatchUpdates

      updates = mappedValueList as PatchUpdates
    }

    // Set action type with parsed command
    actionType = {
      actionIdentifier: params.actionIdentifier,
      type: action.actionType.typeEnum,
      updates,
    }
  } else if (action) {
    actionType = {
      actionIdentifier: params.actionIdentifier,
      type: action.actionType.typeEnum,
      updates: [],
    }
  }

  return actionType
}

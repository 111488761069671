import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

export const enum MockType {
  Success = 'success',
  Error = 'error',
  Cancellation = 'cancellation',
}

export const MockRedirect = (props: { type: MockType }) => {
  const [searchParams] = useSearchParams()
  return (
    <Wrapper>
      <Container>
        <h1 style={{ fontFamily: 'Arial' }}>Redirect mock</h1>
        <h2 style={{ fontFamily: 'Arial' }}>
          {props.type} | {searchParams.get('lang')}
        </h2>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

const Container = styled.div`
  width: 300px;
  height: 200px;
`

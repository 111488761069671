import { AppConfig } from '../../../../types/app.types'

export const verifyEmail = async (params: {
  appConfig: AppConfig
  emailToken: string
  financingSessionToken: string
}): Promise<{ session: null | string; error?: string }> => {
  const headers = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Authorization', `Bearer ${params.financingSessionToken}`)

  const verifyEmailResponse = await fetch(
    `${params.appConfig.app.whitelabelFinancingApiUrl}/verify_email?email_token=${params.emailToken}`,
    {
      method: 'GET',
      headers,
    },
  )

  if (!verifyEmailResponse.ok) {
    return { session: null, error: 'Email verification failed' }
  }

  return { session: params.financingSessionToken }
}

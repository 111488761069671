import { format as formatDate, isValid as isDateValid } from 'date-fns'
import dayjs from 'dayjs'
import { DeprecatedLanguage as Language } from 'frr-web/lib/theme/language'
import i18next from 'i18next'
import LocizeBackend, { LocizeBackendOptions } from 'i18next-locize-backend'
import { initReactI18next } from 'react-i18next'
import { relativeTimeFromNow } from '../../../shared/helpers.dateTime'
import { locizePlugin } from './initLocize'
import { UserLanguage } from '../../../types/frontend.types'

import 'dayjs/locale/de'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import { AppConfig } from '../../../types/app.types'
import { Tracker } from '../../../types/analytics.types'

const DEBUG = false

// Set (default) locale to user locale in moment
const getLocizeBackendOptions = (appConfig: AppConfig): LocizeBackendOptions => ({
  projectId: appConfig.locize.productId,
  apiKey: appConfig.locize.apiKey,
  referenceLng: Language.DE,
  version: appConfig.locize.version,
})

export const initI18next = (params: {
  appConfig: AppConfig
  initialLanguage?: string
  onboardingChannelNamespace?: string
  partnerNamespace?: string
  tracker: Tracker
}) => {
  const ns = ['common']
  const fallbackNS = ['common']
  if (params.onboardingChannelNamespace) {
    ns.push(params.onboardingChannelNamespace)
    fallbackNS.push(params.onboardingChannelNamespace)
  }
  if (params.partnerNamespace) ns.push(params.partnerNamespace)

  i18next
    // load translation using the locize service
    .use(LocizeBackend)
    .use(locizePlugin)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: params.appConfig.app.whitelabelEnvironment === 'dev' && DEBUG,
      load: 'languageOnly',
      lng: params.initialLanguage || Language.DE,
      fallbackLng: 'de',
      supportedLngs: ['en', 'de', 'it', 'fr'],
      nonExplicitSupportedLngs: true,

      fallbackNS,
      defaultNS: params.partnerNamespace || params.onboardingChannelNamespace || 'common',
      ns,

      backend: getLocizeBackendOptions(params.appConfig),
      saveMissing: false, // reports missing keys to locize, requires api key to be set
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        if (
          key !== 'header.htmlTitle' &&
          !key.endsWith('-info') &&
          isNaN(Number.parseInt(key)) &&
          !Object.values(UserLanguage).includes(key as any)
        ) {
          params.tracker.trackMissingTranslation({
            key,
            ns,
            language: lng[0],
          })
        }
      },

      interpolation: {
        escapeValue: false, // react already safes from xss

        format: (value, format) => {
          let formattedValue = value

          if (format === 'Date') {
            formattedValue = isDateValid(value) ? formatDate(value, 'P') : ''
          } else if (format === 'Amount') {
            formattedValue = new Intl.NumberFormat('de-CH', {
              style: 'currency',
              currency: 'CHF',
              minimumIntegerDigits: 1,
            }).format(value.amount)
          } else if (format === 'ListIndex') {
            formattedValue = parseInt(value) + 1
          } else if (format === 'RelativeTime') {
            formattedValue = relativeTimeFromNow(value)
          }

          return formattedValue
        },
      },

      react: {
        bindI18n: 'languageChanged editorSaved',
      },
    })
}

// catch the event and make changes accordingly
i18next.on('languageChanged', (lng) => {
  // set the dayjs locale with the current language
  dayjs.locale(lng)
})

export enum ModuleSystem {
  Calculator = 'Calculator',
  IdentityDataRetrieval = 'IdentityDataRetrieval',
  ContactInformation = 'ContactInformation',
  Income = 'Income',
  Expenses = 'Expenses',
  Review = 'Review',
  DFSLoading = 'DFSLoading',
  Confirmation = 'Confirmation',
  Rejection = 'Rejection',
  Error = 'Error',
  SessionExpired = 'SessionExpired',
  CatchAllSystem = 'CatchAllSystem',
}

export enum ModuleSystemGroup {
  Calculator = 'Calculator',
  ContactInformation = 'ContactInformation',
  Income = 'Income',
  Employment = 'Employment',
  Expenses = 'Expenses',
  Review = 'Review',
  NoNavigation = 'NoNavigation',
  Documents = 'Documents',
}

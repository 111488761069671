import React, { ReactNode } from 'react'

type NavigationState = {
  isNavigationHidden: boolean
  isHeaderDarkMode: boolean
  isChangeLanguageDisabled: boolean
  isPatchOnChangeLanguageActive: boolean
}

type ContextT = {
  setNavigation: (value: { isNavigationHidden: boolean; isHeaderDarkMode?: boolean }) => void
  setNavigationChangeLanguage: (value: {
    isChangeLanguageDisabled: boolean
    isPatchOnChangeLanguageActive: boolean
  }) => void
} & NavigationState

export const useWhitelabelNavigation = (): ContextT => {
  const data = React.useContext(WhitelabelNavigationContext)

  if (!data) {
    throw new Error('Data not found')
  }

  return data
}

export const WhitelabelNavigationContext = React.createContext<ContextT | undefined>(undefined)
WhitelabelNavigationContext.displayName = 'WhitelabelNavigationContext'

export const NavigationProvider = (props: { children: ReactNode }) => {
  const [navigation, setNavigation] = React.useState<NavigationState>({
    isNavigationHidden: false,
    isHeaderDarkMode: false,
    isChangeLanguageDisabled: false,
    isPatchOnChangeLanguageActive: false,
  })

  return (
    <WhitelabelNavigationContext.Provider
      value={{
        ...navigation,
        setNavigation: (v) =>
          setNavigation({
            ...navigation,
            isNavigationHidden: v.isNavigationHidden,
            isHeaderDarkMode: !!v.isHeaderDarkMode,
          }),
        setNavigationChangeLanguage: (v) =>
          setNavigation({
            ...navigation,
            isChangeLanguageDisabled: v.isChangeLanguageDisabled,
            isPatchOnChangeLanguageActive: v.isPatchOnChangeLanguageActive,
          }),
      }}
    >
      {props.children}
    </WhitelabelNavigationContext.Provider>
  )
}

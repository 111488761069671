import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { Browser, detect } from 'detect-browser'
import { MediaQuery } from 'frr-web/lib/theme/configure.theme'
import { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { useTracker } from '../../context/Tracker.context'
const errorText = [
  {
    title: 'Ihr Browser wird nicht unterstützt.',
    subtitle:
      'Um diese Website zu nutzen, verwenden Sie bitte eine aktuelle Version von Chrome, Firefox, Safari oder Edge.',
  },
  {
    title: "Votre navigateur n'est pas compatible.",
    subtitle:
      'Pour utiliser ce site Internet, veuillez utiliser une version récente de Chrome, Firefox, Safari ou Edge.',
  },
  {
    title: 'Il Suo browser non è supportato.',
    subtitle:
      'Per poter visualizzare questo sito web, La preghiamo di utilizzare una versione aggiornata di Chrome, Firefox, Safari o Edge.',
  },
]

const browser = detect()
/* List of all browsers detected (as of 2021-06-10)
  | 'android'
  | 'aol'
  | 'bb10'
  | 'beaker'
  | 'chrome'
  | 'chromium-webview'
  | 'crios'
  | 'curl'
  | 'edge-chromium'
  | 'edge-ios'
  | 'edge'
  | 'facebook'
  | 'firefox'
  | 'fxios'
  | 'ie'
  | 'instagram'
  | 'ios-webview'
  | 'ios'
  | 'kakaotalk'
  | 'miui'
  | 'opera-mini'
  | 'opera'
  | 'phantomjs'
  | 'safari'
  | 'samsung'
  | 'searchbot';
  | 'silk'
  | 'yandexbrowser'
 */
const supportedBrowserList: Array<Partial<Browser>> = [
  'android',
  'chrome',
  'chromium-webview',
  'edge',
  'edge-chromium',
  'firefox',
  'ios',
  'safari',
  // --> Newly added on 28.4.2022
  'facebook',
  'instagram',
  'opera',
  'samsung',
]

export const BrowserError = (props: { children: ReactNode }) => {
  const { tracker } = useTracker()

  const isUnsupportedBrowser =
    (browser &&
      !supportedBrowserList.find((supportedBrowser) => browser.name.includes(supportedBrowser))) ||
    window.location.pathname.endsWith('/error/browser')

  useEffect(() => {
    browser &&
      isUnsupportedBrowser &&
      tracker.trackException({
        exception: new Error(`unsupported brower - ${browser.name}`),
        severity: SeverityLevel.Warning,
      })
  }, [isUnsupportedBrowser])

  return isUnsupportedBrowser ? (
    <Wrapper>
      <Container>
        <img src={'/warning.svg'} style={{ width: 144, height: 144 }} alt='warning' />
        {errorText.map((p) => (
          <>
            <h3>{p.title}</h3>
            <p style={{ marginBottom: '24px' }}>{p.subtitle}</p>
          </>
        ))}
      </Container>
    </Wrapper>
  ) : (
    <>{props.children}</>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: var(--view-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  flex-direction: column;
`
const Container = styled.div`
  width: 680px;
  height: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.03);

  @media ${MediaQuery.Mobile} {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  & > h1 {
    max-width: 480px;
    font-size: 32px;
    font-weight: 700;
    margin: 40px 16px 8px;
    font-family: Helvetica;
    text-align: center;
  }

  & > h3 {
    max-width: 480px;
    font-size: 18px;
    opacity: 0.5;
    margin: 8px 16px;
    font-family: Helvetica;
    text-align: center;
  }

  & > p {
    max-width: 480px;
    font-size: 14px;
    opacity: 0.5;
    margin: 8px 16px 0px;
    font-family: Helvetica;
    text-align: center;
  }
`

import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useProduct } from '../context/PartnerProduct.context'

export const HelmetProvider = () => {
  const { product, configuration } = useProduct()

  const { t, i18n } = useTranslation(configuration.otherConfig.languageNamespacePartner)

  const title: string =
    (configuration.otherConfig.languageNamespacePartner &&
      t('header.htmlTitle') !== 'header.htmlTitle' &&
      t('header.htmlTitle')) ||
    product.displayName ||
    ''

  return (
    <Helmet htmlAttributes={{ lang: i18n.language, translate: 'no' }}>
      <title>{title}</title>
      <link
        rel='shortcut icon'
        href={`https://stbobfspfrontendassets.blob.core.windows.net/bobzeroassets/${configuration.headerConfig.favicon}`}
      />
    </Helmet>
  )
}

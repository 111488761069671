import { AppConfig } from '../../types/app.types'
import { FinancingSessionStorage } from '../../view/providers/FinancingSession.provider'
import { PartnerSessionStorage } from '../../view/providers/PartnerSession.provider'
import { OpenAPI as WhitelablAPI } from './openapi'
import { OpenAPI as PartnerOnboardingAPI } from './openapiPartnerOnboarding'

const getSessionToken = async () => {
  return Promise.resolve(
    FinancingSessionStorage.getSession() || window.localStorage.getItem('azureToken') || 'no-session',
  )
}

const getPartnerSessionToken = async () => {
  return Promise.resolve(
    PartnerSessionStorage.getSession() || window.localStorage.getItem('azureToken') || 'no-session',
  )
}

export const initOpenapi = (appConfig: AppConfig) => {
  WhitelablAPI.TOKEN = getSessionToken
  WhitelablAPI.BASE = appConfig.app.whitelabelBobBackendApiUrl

  PartnerOnboardingAPI.TOKEN = getPartnerSessionToken
  PartnerOnboardingAPI.BASE = appConfig.app.whitelabelBobBackendApiUrl
}

import { createContext, ReactNode, useContext, useState } from 'react'
import { UserLanguage } from '../../types/frontend.types'
import { useDispatch, useSelector } from 'react-redux'
import { appActions, appSelectors } from '../../data/redux/appState.redux'

type LanguageContextT = {
  language: UserLanguage | null
  setLanguage: (value: UserLanguage | null) => void
}
// const LanguageContext = createContext<LanguageContextT>({
//   language: null,
//   setLanguage: () => {},
// })
// LanguageContext.displayName = 'LanguageContext'

export const useLanguage = (): LanguageContextT => {
  const dispatch = useDispatch()
  const language = useSelector(appSelectors.language)

  return { language, setLanguage: appActions(dispatch).setLanguage }
}

// export const LanguageProvider = (props: { children: ReactNode }) => {
//   const [language, setLanguage] = useState<UserLanguage | null>(null)

//   return (
//     <LanguageContext.Provider value={{ language, setLanguage }}>
//       {props.children}
//     </LanguageContext.Provider>
//   )
// }

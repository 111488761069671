import { useMutation } from '@tanstack/react-query'
import { PartnerInfo } from '../../types/partner.types'
import { useModuleLoaderContext } from '../../view/context/ModuleLoader.context'
import { getPartnerInfoOutbound } from '../api/api.partnerOnboarding.helpers'
import { ApiError, BobPartnerFacadeOnboardingService } from '../api/openapiPartnerOnboarding'
import { createPartnerOnboardingSessionLink } from '../api/fetchapi/partnerOnboarding/createPartnerOnboardingSessionLink'
import { useAppConfig } from '../../view/providers/AppConfigData.provider'

export const useCreatePartner = (/* config: { partnerOnboardingClientCtx: string } */) => {
  const { setModuleLoaderContext } = useModuleLoaderContext()

  const createPartner = useMutation({
    mutationFn: (params: {
      errorModuleSystem: string
      partnerInfo: PartnerInfo
      successModuleSystem: string
    }) => {
      setModuleLoaderContext({
        isApiError: false,
        isModuleSystemLoading: true,
        isSameModuleSystem: false,
      })

      const requestBody = getPartnerInfoOutbound(params.partnerInfo)

      return BobPartnerFacadeOnboardingService.postBobPartnerFacadeOnboardingCreatePartner({
        requestBody,
      })
    },

    onSuccess: async (_, meta) => {
      setModuleLoaderContext({
        isModuleSystemLoading: false,
        moduleSystemOverride: meta.successModuleSystem,
      })
    },

    onError: (error: ApiError, meta) => {
      console.error('💥 ERROR WHILE CREATE PARTNER', error)

      setModuleLoaderContext({
        apiErrors: error.body.errors ? Object.keys(error.body.errors as Record<string, any>) : undefined,
        isApiError: true,
        isModuleSystemLoading: false,
        moduleSystemOverride: meta.errorModuleSystem,
      })
    },
  })

  return { createPartner }
}

export const useCreatePartnerOnboardingSession = () => {
  const { setModuleLoaderContext } = useModuleLoaderContext()
  const { appConfig } = useAppConfig()

  const createPartnerOnboardingSession = useMutation({
    mutationFn: async () => {
      const partnerOnboardingSessionLink = await createPartnerOnboardingSessionLink({ appConfig })

      let partnerOnboardingSession: string | null = null
      if (partnerOnboardingSessionLink.url && partnerOnboardingSessionLink.url.includes('?')) {
        const urlSearchParams = new URLSearchParams(partnerOnboardingSessionLink.url.split('?')[1])
        partnerOnboardingSession = urlSearchParams.get('session')
      }

      return partnerOnboardingSession
    },

    onError: (error: ApiError, meta) => {
      console.error('💥 ERROR WHILE REQUESTING PARTNER ONBOARDING SESSION', error)

      setModuleLoaderContext({
        apiErrors: error.body.errors ? Object.keys(error.body.errors as Record<string, any>) : undefined,
        isApiError: true,
        isModuleSystemLoading: false,
      })
    },
  })

  return { createPartnerOnboardingSession }
}

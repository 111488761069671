import { Translate } from '../../types/helper.types'
import { Microsoft_AspNetCore_JsonPatch_Operations_Operation } from './openapi'

export enum QueryKey {
  PARTNER_VIEW_FOR_FINANCING = 'Partner View For Financing',
  FINANCING_OBJECT = 'Financing Object',
  PRODUCT = 'Product',
  PRODUCT_CONFIGURATION = 'Product Configuration',
  PRODUCT_CONFIGURATION_LIST = 'Partner Product Configuration List',
}

export type PatchUpdates = Array<Microsoft_AspNetCore_JsonPatch_Operations_Operation>

export type PatchNotifications = {
  error?: string
  success?: string
}

export type PatchFinObjParams<FinancingObject extends {}> = {
  isHideLoading?: boolean
  isSameModuleSystem?: boolean
  nextModuleSystem?: string
  notifications?: PatchNotifications
  onSuccess?: (financingObject: FinancingObject, t: Translate) => void
  updates: PatchUpdates
}

import { createContext, ReactNode, useContext, useState } from 'react'

type ModuleLoaderContextData = {
  apiErrors: Array<string>
  isApiError: boolean
  isModuleSystemLoading: boolean
  isSameModuleSystem: boolean
  moduleSystemOverride: string | null
}
type ModuleLoaderContextT = ModuleLoaderContextData & {
  setModuleLoaderContext: (value: Partial<ModuleLoaderContextData>) => void
}
const ModuleLoaderContext = createContext<ModuleLoaderContextT>({
  apiErrors: [],
  isApiError: false,
  isModuleSystemLoading: false,
  isSameModuleSystem: false,
  moduleSystemOverride: null,
  setModuleLoaderContext: () => {},
})
ModuleLoaderContext.displayName = 'ModuleLoaderContext'

export const useModuleLoaderContext = (): ModuleLoaderContextT => {
  return useContext(ModuleLoaderContext)
}

export const ModuleLoaderProvider = (props: { children: ReactNode }) => {
  const [apiErrors, setApiErrors] = useState<Array<string>>([])
  const [isApiError, setIsApiError] = useState(false)
  const [isModuleSystemLoading, setIsModuleSystemLoading] = useState(false)
  const [isSameModuleSystem, setIsSameModuleSystem] = useState(false)
  const [moduleSystemOverride, setModuleSystemOverride] = useState<string | null>(null)

  return (
    <ModuleLoaderContext.Provider
      value={{
        apiErrors,
        isApiError,
        isModuleSystemLoading,
        isSameModuleSystem,
        moduleSystemOverride,

        setModuleLoaderContext: (values) => {
          if (values.apiErrors !== undefined) {
            setApiErrors(values.apiErrors)
          }
          if (values.isApiError !== undefined) {
            setIsApiError(values.isApiError)
          }
          if (values.isModuleSystemLoading !== undefined) {
            setIsModuleSystemLoading(values.isModuleSystemLoading)
          }
          if (values.isSameModuleSystem !== undefined) {
            setIsSameModuleSystem(values.isSameModuleSystem)
          }
          if (values.moduleSystemOverride !== undefined) {
            setModuleSystemOverride(values.moduleSystemOverride)
          }
        },
      }}
    >
      {props.children}
    </ModuleLoaderContext.Provider>
  )
}

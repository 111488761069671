import { ReactNode, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePatchFinObj } from '../../data/mutations/financingObject.mutations'
import { ModuleSystem } from '../../engines/bobpay/customerVerfication/bobpayCustomerVerification.types'
import { OnboardingChannel } from '../../types/financing.types'
import { useModuleLoaderContext } from '../context/ModuleLoader.context'
import { useProduct } from '../context/PartnerProduct.context'
import { useTracker } from '../context/Tracker.context'
import { EmailValidator } from '../modules/EmailValidator.module'
import { initI18next } from '../scaffold/translation/initI18next'
import { useAppConfig } from './AppConfigData.provider'
import { FinancingSessionStorage } from './FinancingSession.provider'

export const EmailValidationProvider = (props: { children: ReactNode }) => {
  const { appConfig } = useAppConfig()
  const { setModuleLoaderContext } = useModuleLoaderContext()
  const { tracker } = useTracker()
  const patchFinObj = usePatchFinObj()

  const [searchParams] = useSearchParams()
  const emailAddress = searchParams.get('email_address')
  const emailToken = searchParams.get('email_token')
  const financingSessionToken = searchParams.get('financing_session_token')

  const hasSession = !!FinancingSessionStorage.getSession()

  const isValidator = !hasSession && !!emailToken && !!financingSessionToken

  const { product } = useProduct()

  const isBplFlow = [OnboardingChannel.BPL_RETAIL, OnboardingChannel.BPL_ECOMMERCE].includes(
    product.onboardingChannel as OnboardingChannel,
  )

  // If we are in the new bpl flow we show the confirmation screen instead of the generic email validation page
  const [isEmailVerificationDone, setIsEmailVerificationDone] = useState(hasSession)
  let onEmailVerified
  let onEmailLinkExpired

  if (isBplFlow) {
    onEmailVerified = (session: string) => {
      // Patch fin obj with empty payload if a session is returned to move the flow forward
      FinancingSessionStorage.saveSession(session)
      patchFinObj
        .mutateAsync({
          isHideLoading: true,
          isSameModuleSystem: true,
          onSuccess: () => setIsEmailVerificationDone(true),
          updates: [],
        })
        .then(() => {
          setIsEmailVerificationDone(true)
        })
    }

    onEmailLinkExpired = () => {
      setModuleLoaderContext({
        moduleSystemOverride: ModuleSystem.ExpiredEmailLink,
      })
      setIsEmailVerificationDone(true)
    }
  }

  // Do not show anything during initialisation

  const [isInitializing, setIsInitializing] = useState(true)

  useEffect(() => {
    if (isValidator && !isBplFlow) {
      initI18next({ appConfig, tracker })
    }
    setIsInitializing(false)
  }, [isValidator, !!isBplFlow])

  if (isInitializing) {
    return null
  }

  if (!isEmailVerificationDone && emailAddress && emailToken && financingSessionToken) {
    return (
      <EmailValidator
        emailAddress={emailAddress}
        emailToken={emailToken}
        financingSessionToken={financingSessionToken}
        onEmailVerified={onEmailVerified}
        onEmailVerificationFailed={onEmailLinkExpired}
      />
    )
  }

  return <>{props.children}</>
}

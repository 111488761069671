import { createStyled } from 'frr-web/lib/theme/util'
import { ReactNode } from 'react'
import pkg from '../../../package.json'
import { useCSSStyles, useAppTheme } from '../../shared/theme/app.theme'
import { Div } from 'frr-web/lib/html'

export const AppVersionProvider = (props: { children: ReactNode }) => {
  const theme = useAppTheme()
  const getCSSStyles = useCSSStyles(theme, 'app')({})

  return (
    <Div {...getCSSStyles('wrapper')} id='app-content'>
      {props.children}
      <Version {...getCSSStyles('version')}>App Version {pkg.version}</Version>
    </Div>
  )
}

const Version = createStyled('p')

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BobBaseApp_OnboardingChannelVariantEnum {
    UNKNOWN = 'UNKNOWN',
    LONGTERM = 'LONGTERM',
    SHORTTERM = 'SHORTTERM',
    SINGLETERM = 'SINGLETERM',
}

import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { StateLevels } from '../shared/helpers.stateLevel'
import { FinancingObject, OnboardingChannel, ProductSettingsT } from './financing.types'
import { PlatformState, UserType } from './frontend.types'
import { PlatformDataT } from 'frr-web/lib/hooks/usePlatform'

export enum TrackEventType {
  BplParnterResendSMS = 'BPL partner: Resend SMS',
  BplPartnerUpdateAmount = 'BPL: Update amount',
  BplVerifyEmailResendEmail = 'BPL verify email: Resend email',
  BplVerifyIDChangeDocumentType = 'BPL verify ID: Change document type',
  BplVerifyIDCompleted = 'BPL verify ID: Completed',
  BplVerifyIDRetry = 'BPL verify ID: Retry',
  BplVerifyIDServiceStarted = 'BPL verify ID: ID service started',
  BplVerifyIDUnsupportedPlatform = 'BPL verify ID: Unsupported platform',
  ChangeLanguage = 'Change Language',
  Exception = 'Exception',
  MissingTranslation = 'Missing Translation',
  ModuleView = 'ModuleView',
  ReloadCookieBot = 'Reload CookieBot in a different language',
  SubmitForm = 'SubmitForm',
  SubmitFormErrors = 'ErrorsOnSubmit',
}

export type TrackBplVerifyEmailRetry = {
  type: TrackEventType.BplVerifyEmailResendEmail
  hasChanged: boolean
}

export type TrackBplVerifyIDChangeDocumentType = {
  type: TrackEventType.BplVerifyIDChangeDocumentType
  newDocumentType: string
  oldDocumentType: string | undefined
}

export type TrackBplVerifyIDCompleted = {
  type: TrackEventType.BplVerifyIDCompleted
  status: 'Success' | 'Error' | 'Timed-out' | 'Unknown'
}
export type TrackBplPartnerResendSMS = {
  type: TrackEventType.BplParnterResendSMS
  hasChanged: boolean
}
export type TrackBplPartnerUpdateAmount = {
  type: TrackEventType.BplPartnerUpdateAmount
}
export type TrackBplVerifyIDRetry = {
  type: TrackEventType.BplVerifyIDRetry
  isCompleted: boolean
}
export type TrackBplVerifyIDServiceStarted = {
  type: TrackEventType.BplVerifyIDServiceStarted
}

export type TrackBplVerifyIDUnsupportedPlatform = {
  type: TrackEventType.BplVerifyIDUnsupportedPlatform
  platformState: PlatformState
  isIgnored?: boolean
}

export type TrackChangeLanguage = {
  type: TrackEventType.ChangeLanguage
  newLanguage: string
  oldLanguage: string | undefined
}

export type TrackException = {
  type: TrackEventType.Exception
  exception: Error
  customProperties?: Record<string, any>
  severity: SeverityLevel
}

export type TrackMissingTranslation = {
  key: string
  ns: string
  language: string
}

export type TrackModuleView = {
  type: TrackEventType.ModuleView
  language?: string
}

export type TrackReloadCookieBot = {
  type: TrackEventType.ReloadCookieBot
  hasReload: boolean
}

export type TrackSubmitForm = {
  type: TrackEventType.SubmitForm
  data?: Record<string, any>
}

export type TrackSubmitFormErrors = {
  type: TrackEventType.SubmitFormErrors
  errors: Array<string>
}

export type TrackEvent =
  | TrackBplPartnerResendSMS
  | TrackBplPartnerUpdateAmount
  | TrackBplVerifyEmailRetry
  | TrackBplVerifyIDChangeDocumentType
  | TrackBplVerifyIDCompleted
  | TrackBplVerifyIDRetry
  | TrackBplVerifyIDServiceStarted
  | TrackBplVerifyIDUnsupportedPlatform
  | TrackChangeLanguage
  | TrackException
  | TrackModuleView
  | TrackReloadCookieBot
  | TrackSubmitForm
  | TrackSubmitFormErrors

export type TrackEventData = {
  additionalData?: Record<string, any>
  errors?: Array<string>
  finObj?: FinancingObject
  language?: string
  moduleSystemInfo:
    | {
        moduleSystemId?: string
        moduleType?: string | undefined
        moduleState?: string
      }
    | undefined
  platformData: PlatformDataT
  product: ProductSettingsT
  rejectionReason?: string
  stateLevels?: StateLevels
}

export type Tracker = {
  hasAppInsightsTracking: boolean
  isInitialized: boolean
  operationId: () => string | null
  addPartner: (payload: ProductSettingsT) => void
  trackPageView: (params: TrackEventData) => void
  trackEvent: (payload: { name: string } & TrackEventData) => void
  trackException: (payload: Omit<TrackException, 'type'>) => void
  trackMissingTranslation: (payload: TrackMissingTranslation) => void
}

// App Insights types
export type AppInsights_AdditionalTrackProperties = Record<
  string,
  string | number | boolean | undefined | null
>
export type AppInsights_TrackProperties = {
  additionalTrackProps?: AppInsights_AdditionalTrackProperties
  errors?: Array<string>
  finObjState?: string
  language?: string
  moduleState?: string
  moduleSystem?: string
  moduleType?: string
  onboardingChannel?: OnboardingChannel
  partner?: string
  // Added for bpl flow
  'Device media'?: 'Desktop' | 'Mobile'
  'Financing id'?: number
  'Rejection reason'?: string
  'User type'?: UserType
}

// Google Tag Manager types
export enum GTM_Events {
  Configuration = 'Configuration',
  VirtualPageView = 'VirtualPageView',
  ApplicationSubmitted = 'ApplicationSubmitted',
  ApplicationRejected = 'ApplicationRejected',
  ApplicationSuccess = 'ApplicationSuccess',
}

type GTM_TrackApplicationData = {
  applicationAmount?: number | null
  applicationCurrency?: string
  applicationDuration?: number | null
  applicationInsurance?: number
  applicationId?: number
  applicationLanguage?: string
}

export type GTM_TrackApplicationEventData = {
  event: GTM_Events
} & GTM_TrackApplicationData

export type GTM_TrackPageViewData = {
  event: GTM_Events.VirtualPageView
  virtualPageTitle: string
  virtualPageUrl: string
} & GTM_TrackApplicationData

export type GTMClientData = {
  client_id?: string | null
  session_id?: string | null
  gclid?: string | null
}

import { getPartnerExternalIdFromSession } from '../../shared/helpers'
import { PartnerInfo, PartnerInfoBE } from '../../types/partner.types'

export const getPartnerInfoOutbound = (partnerInfo: PartnerInfo): PartnerInfoBE => {
  return {
    company: {
      ...partnerInfo.company,
      partnerIdentifier: getPartnerExternalIdFromSession(),
    },
    bankAccount: partnerInfo.bankAccount,
    contactPersons: partnerInfo.contactPersons.map((contactPerson) => ({
      ...contactPerson,
      shareOfCompany: contactPerson.shareOfCompany ? contactPerson.shareOfCompany / 100 : undefined,
      hasSigningAuthority:
        contactPerson.hasSigningAuthority === null ? false : contactPerson.hasSigningAuthority,
    })),
  }
}

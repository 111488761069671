import { Language } from 'frr-web/lib/theme/language'
import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { usePatchFinObj } from '../../data/mutations/financingObject.mutations'
import { i18nLang } from '../../shared/constants'
import { getUserTypeFromSession } from '../../shared/helpers'
import { TrackEventType } from '../../types/analytics.types'
import { OnboardingChannel } from '../../types/financing.types'
import { UserLanguage } from '../../types/frontend.types'
import { useOptionalFinObj } from '../context/Data.context'
import { useLanguage } from '../context/Language.context'
import { useWhitelabelNavigation } from '../context/Navigation.context'
import { useProduct } from '../context/PartnerProduct.context'
import { useTrackEvent } from '../hooks/useTrackEvent'
import { Loading } from '../scaffold/Loading'
import { useHostname } from '../hooks/useHostname'

export type UrlLanguageConfig = { translations: any }

// TODO: Language is a hot mess. We need to fix this
const cleanLanguage: Record<UserLanguage | Language, UserLanguage> = {
  [UserLanguage.DE]: UserLanguage.DE,
  [Language.DE_CH]: UserLanguage.DE,
  [Language.FR]: UserLanguage.FR,
  [Language.EN]: UserLanguage.EN,
  [Language.IT]: UserLanguage.IT,
  [UserLanguage.DEV]: UserLanguage.DEV,
}

export const useChangeLanuage = () => {
  const { urlHostname } = useHostname()
  const { product } = useProduct()

  // Get url search params, set partner and leave the rest
  const [search] = useSearchParams()
  const seachPartnerVariantId = urlHostname ? product.variantId : ''

  const isBplFlow = [OnboardingChannel.BPL_RETAIL, OnboardingChannel.BPL_ECOMMERCE].includes(
    product.onboardingChannel as OnboardingChannel,
  )

  if (seachPartnerVariantId !== '' && !isBplFlow && !product.isPartnerOnboarding) {
    search.set('partner', seachPartnerVariantId)
  }

  const navigate = useNavigate()
  const trackEvent = useTrackEvent('useChangeLanuage')

  return (lang: string | UserLanguage, oldLanguage?: string) => {
    trackEvent({
      type: TrackEventType.ChangeLanguage,
      newLanguage: lang as string,
      oldLanguage,
    })
    navigate(`/${lang}${search.toString() > '' ? `?${search.toString()}` : ''}`)
  }
}

export const UrlLanguageProvider = (props: { children?: ReactNode }) => {
  const params = useParams<{ lang?: string }>()
  const { i18n } = useTranslation()
  const { product, configuration } = useProduct()

  const patchFinObj = usePatchFinObj()
  const userType = getUserTypeFromSession()
  const optionalFinObj = useOptionalFinObj()

  const isBplFlow = [OnboardingChannel.BPL_RETAIL, OnboardingChannel.BPL_ECOMMERCE].includes(
    product.onboardingChannel as OnboardingChannel,
  )

  // Get languages from url, state and fin object
  const { language, setLanguage } = useLanguage()
  const urlLanguage = params.lang
    ? cleanLanguage[params.lang as unknown as UserLanguage | Language]
    : undefined

  const finObjLangauge = optionalFinObj ? optionalFinObj.customerInfo.baseInfo.language : undefined

  // Get url search params, set partner and leave the rest
  const changeLanguage = useChangeLanuage()

  // Set language in i18n
  const onLanguageChanged = (lang: UserLanguage | undefined) => {
    if (lang && i18n && i18n.changeLanguage) {
      i18n.changeLanguage(i18nLang[lang])
      setLanguage(lang)
    }
  }

  // Patch language in financing object
  const { isPatchOnChangeLanguageActive } = useWhitelabelNavigation()

  useEffect(() => {
    if (
      isPatchOnChangeLanguageActive &&
      finObjLangauge !== null &&
      i18n.language !== finObjLangauge &&
      i18n.language !== i18nLang[UserLanguage.DEV]
    ) {
      patchFinObj.mutate({
        isSameModuleSystem: true,
        updates: [
          {
            value: urlLanguage,
            path: '/customer/baseInfo/language',
            op: 'replace',
          },
        ],
      })
    }
  }, [finObjLangauge, i18n.language, isPatchOnChangeLanguageActive, isBplFlow])

  // Change language in url and i18n
  useEffect(() => {
    if (urlLanguage !== language) {
      if (
        (urlLanguage && (configuration.otherConfig.disableLanguages || []).includes(urlLanguage)) ||
        !Object.values(UserLanguage).includes(urlLanguage as UserLanguage)
      ) {
        changeLanguage(configuration.otherConfig.defaultLanguage || UserLanguage.DE, undefined)
      } else if (i18n) {
        onLanguageChanged(urlLanguage)
      }
    } else if (i18n && (urlLanguage as any) !== i18n.language) {
      onLanguageChanged(urlLanguage)
    }
  }, [language, urlLanguage, i18n.language])

  // Set customer.baseInfo.language in financing object as default for the user language
  useEffect(() => {
    const isPartnerBplRetailFlow = isBplFlow && userType === 'partner'

    if (
      finObjLangauge &&
      finObjLangauge !== (urlLanguage as any) &&
      !product.isBobInternalFlow &&
      !isPartnerBplRetailFlow
    ) {
      changeLanguage(finObjLangauge, urlLanguage as string | undefined)
    }
  }, [finObjLangauge, userType])

  return urlLanguage ? <>{props.children}</> : <Loading label='loading language' />
}

import { ReactNode, useLayoutEffect } from 'react'
import { AppQueryKey, useAppConfigQuery } from '../../data/queries/app.queries'
import { initOpenapi } from '../../data/api/api.init'
import { useQueryClient } from '@tanstack/react-query'
import { AppConfig } from '../../types/app.types'

type ConfigProviderProps = { children: ReactNode }

export const useAppConfig = () => {
  const queryClient = useQueryClient()
  const appConfig = queryClient.getQueryData<AppConfig>(AppQueryKey.AppConfig())

  if (!appConfig) {
    throw new Error('App config not found')
  }

  return { appConfig }
}

export const AppConfigDataProvider = (props: ConfigProviderProps) => {
  const { data, isLoading, isError } = useAppConfigQuery()

  // Initialize OpenAPI
  useLayoutEffect(() => {
    if (data) initOpenapi(data)
  }, [data])

  if (isLoading) return null

  if (isError || !data) {
    throw new Error('AppConfigDataProvider: Could not load AppConfig')
  }

  return <>{props.children}</>
}

import { ModuleThemeConfig } from '../../shared/theme/module.theme'

export const moduleTheme: ModuleThemeConfig = {
  // ==============================
  // Calculation
  // ==============================

  calculator: {
    headerWrapper: {
      marginBottom: 48,
      '@media-mobile': {
        marginBottom: 24,
      },
    },
    headerText: {
      color: 'var(--color-primary)',
      fontSize: 'var(--font-size-title)',
      fontWeight: 400,
    },
    introduction: {
      fontSize: 18,
      fontWeight: 400,
      marginBottom: 32,
    },
    footerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'var(--flex-end-mobile-center)',
      marginTop: 32,
      padding: '0 var(--form-padding-horizontal)',
      height: 80,
      width: '100%',

      '@media-mobile': {
        flexWrap: 'wrap',
      },
    },
    calculatorContent: {
      display: 'var(--display-flex-mobile-block)',
      gap: 96,
      width: '100%',
    },
    calculatorWrapper: {
      padding: 48,
      borderRadius: 4,
      marginBottom: 8,
      boxShadow: '0 0 8px 8px rgb(0 0 0 / 4%)',
      backgroundColor: 'var(--color-background-primary)',
      flex: 1,
      '@media-mobile': {
        padding: '48px 24px',
      },
    },
    containerLeft: {
      flex: 3,
      maxWidth: 450,
      paddingTop: 3,
      paddingBottom: 'var(--form-field-mobile-padding)',
      '@media-mobile': {
        minWidth: '100%',
        paddingRight: 0,
      },
    },
    containerRight: {
      flex: 2,
      marginBottom: 24,
      width: '100%',
      '@media-mobile': {
        display: 'block',
      },
    },
    containerRightCheckout: {
      maxWidth: 600,
      minWidth: 400,
      marginTop: -11,
      width: '100%',
      flexGrow: 1,
    },
    monthlyRate: {
      position: 'relative',
      fontWeight: 400,
      fontSize: 18,
      marginTop: 8,
    },
    previewMonthlyItems: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 4,
      marginBottom: 12,
      gap: 24,
      '@media-mobile': {
        flexWrap: 'wrap',
      },
    },
    previewMonthlyItem: {
      marginTop: 8,
      flexGrow: 1,
      '@media-mobile': {
        marginTop: 0,
        width: 'fit-content',
      },
    },
    previewMonthlyRate: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 8,
      color: 'var(--color-primary)',
      width: 'fit-content',
      '@media-mobile': {
        fontSize: 18,
      },
    },
    previewInsurance: {
      fontWeight: 400,
      fontSize: 12,
      opacity: 0.7,
      marginBottom: 0,
      '@media-mobile': {
        width: 'calc(50vw - 12px - var(--form-padding-horizontal))',
      },
    },
    previewInterest: {
      background: 'rgb(0, 145, 178, 0.1)',
      borderRadius: 4,
      color: '#014b5c',
      fontSize: 13,
      fontWeight: 400,
      marginBottom: 8,
      padding: '4px 12px 4px',
      textAlign: 'center',
      width: 'fit-content',
    },
    previewForm: {
      border: '1px solid var(--color-form-field-border)',
      borderRadius: 6,
      padding: '4px 24px',
      position: 'relative',
      top: -9,
      transition: 'border 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      '@media-mobile': {
        marginTop: 64,
        padding: '12px 12px',
        top: 0,
      },
    },
    previewFormPlaceholder: {
      border: '1px solid var(--color-form-field-border)',
      boxShadow: 'none',
    },
  },

  // ==============================
  // Contract module
  // ==============================

  contract: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      zIndex: 2,
    },
    bottomButtonWrapper: {
      marginTop: 48,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 'var(--font-size-title)',
    },

    pdfWrapper: {
      position: 'relative',
      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
      borderRadius: 8,
      width: 800,
      minHeight: 1150,
      marginTop: 32,
    },
    subtitle: {
      marginTop: 16,
      maxWidth: 600,
      fontWeight: 500,
      textAlign: 'center',
      fontSize: 'var(--font-size-p)',
    },
    documentLinkWrapper: {
      marginBottom: 64,
      display: 'flex',
      justifyContent: 'flex-end',
      background: 'rgba(0,0,0,0.08)',
      maxWidth: 800,
      padding: '32px 16px',
      gap: 32,
      width: '100%',
      '@media-mobile': {
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'auto',
        marginBottom: 48,
      },
    },
    documentLink: {
      padding: '16px 32px',
      fontSize: 16,
      borderRadius: 32,
      cursor: 'pointer',
      textDecoration: 'underline',
      color: 'black',

      ':hover': {
        opacity: 0.8,
      },
    },
  },

  // ==============================
  // Contact section component
  // ==============================

  contactSection: {
    title: {
      color: 'var(--color-primary)',
      fontSize: 'var(--font-size-title)',
      fontWeight: 400,
      marginBottom: 8,
      ':readonly': {
        marginBottom: 20,
      },
      '@media-mobile': {
        marginRight: 32,
      },
    },
    description: {
      marginTop: 16,
    },
    contactBoxesWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 32,

      '@media-mobile': {
        flexDirection: 'column',
      },
    },
    contactBox: {
      alignItems: 'center',
      border: '1px solid rgba(0,0,0,0.3)',
      borderRadius: 'var(--button-border-radius)',
      display: 'flex',
      height: 72,
      marginRight: 16,
      minWidth: 256,
      padding: '0 48px 0 16px',

      '@media-mobile': {
        margin: '8px 0',
      },
    },
    contactLink: {
      textDecoration: 'none',
      '@media-mobile': {
        width: '100%',
      },
    },
    contactBoxIcon: {
      marginRight: 10,
    },
    contactBoxText: {
      fontWeight: 500,
      fontSize: 'var(--font-size-p)',
    },
    wrapper: {
      width: '100%',
    },
  },

  // ==============================
  // Customer ID Verification
  // ==============================

  customerIDVerification: {
    wrapper: {
      background: 'var(--color-background-primary)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      padding: '32px var(--form-padding-horizontal) 48px',
      width: '100%',
      '@media-mobile': {
        minHeight: 'calc(var(--module-content-min-height) + 32px)',
        paddingTop: 0,
        paddingBottom: 0,
        transition: 'min-height 0.5s ease-in-out',
      },
    },
    buttonWrapper: {
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '48px 0',
      '@media-mobile': {
        marginLeft: 0,
        marginRight: 0,
        padding: '16px 0 64px',
      },
    },
    innerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      paddingTop: 32,
    },
    iFrameWrapper: {
      minHeight: 640,
      maxHeight: 'var(--module-content-min-height)',
      padding: 0,
      transition: 'max-height 0.5s ease-out',
      '@media-mobile': {
        minHeight: 'var(--module-content-min-height)',
        maxHeight: 'unset',
      },
    },
    iFrameHiddenWrapper: {
      maxHeight: 'unset',
    },
    description: {
      margin: '0 6px',
    },
  },

  // ==============================
  // Customer Introduction
  // ==============================

  customerIntroduction: {
    wrapper: {
      background: 'var(--color-background-primary)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      padding: '32px var(--form-padding-horizontal) 48px',
      width: '100%',
      '@media-mobile': {
        minHeight: 'calc(var(--module-content-min-height) + 32px)',
        paddingTop: 0,
        paddingBottom: 0,
        transition: 'min-height 0.5s ease-in-out',
      },
    },
    buttonWrapper: {
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '48px 0',
      '@media-mobile': {
        marginLeft: 0,
        marginRight: 0,
        padding: '16px 0 64px',
      },
    },
    innerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      paddingTop: 32,
    },
    description: {
      marginBottom: 24,
    },
    title: {
      fontSize: 'var(--font-size-title)',
      fontWeight: 600,
      marginBottom: 18,
    },
  },

  // ==============================
  // Information modules
  // ==============================

  information: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '@media-mobile': {
        minHeight: 'calc(var(--module-content-min-height) - 32px)',
      },
    },
    outerWrapper: {
      background: 'var(--color-background-primary)',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '32px var(--form-padding-horizontal) 48px',
      overflow: 'hidden',
      width: '100%',
      '@media-mobile': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    innerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      padding: '16px 0',
      overflowX: 'hidden',
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'var(--flex-end-mobile-center)',
      marginTop: 32,
      height: 80,
      padding: '0 var(--form-padding-horizontal)',
      width: '100%',
      '@media-mobile': {
        height: 'auto',
      },
    },
    emailLabel: {
      flexGrow: 0,
      marginBottom: 12,
    },
    emailButtonWrapper: {
      marginTop: 16,
      marginLeft: 'auto',
      marginRight: 'auto',
      '@media-mobile': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    description: {
      marginBottom: '4vh',
    },
    icon: {
      height: 144,
      marginBottom: 16,
      marginLeft: 'auto',
      marginRight: 'auto',
      filter: 'var(--form-icon-filter, none)',
      '@media-mobile': {
        marginBottom: '4vh',
      },
    },
    resendWrapper: {
      marginTop: 48,
      width: '100%',
    },
    resendLabel: {
      color: 'var(--color-secondary)',
      fontSize: 'var(--font-size-label)',
      marginBottom: 24,
    },
    resendErrorLabel: {
      color: 'var(--color-error)',
      fontSize: 'var(--font-size-sublabel)',
      marginTop: -16,
      marginBottom: 8,
    },
    resendInputWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: 440,
      gap: 4,
      marginRight: 12,
      '@media-mobile': {
        width: '100%',
        marginRight: 0,
        paddingBottom: 6,
      },
    },
  },

  // ==============================
  // Processing modules
  // ==============================

  processing: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '@media-mobile': {
        minHeight: 'calc(var(--module-content-min-height) - 32px)',
      },
    },
    outerWrapper: {
      background: 'var(--color-background-primary)',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '32px var(--form-padding-horizontal) 48px',
      margin: '0',
      marginBottom: 16,
      overflow: 'hidden',
      width: '100%',
      '@media-mobile': {
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 0,
      },
    },
    innerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      padding: '16px 0',
      overflowX: 'hidden',
    },
    description: {
      marginBottom: 24,
    },
    icon: {
      height: 144,
      marginTop: 16,
      marginBottom: 16,
      marginLeft: 'auto',
      marginRight: 'auto',
      filter: 'var(--form-icon-filter, none)',
      '@media-mobile': {
        marginBottom: '4vh',
      },
    },
    list: {
      marginTop: 0,
      paddingLeft: 32,
    },
    listItem: {
      fontSize: 'var(--font-size-p)',
      lineHeight: 1.4,
      marginBottom: 16,
      paddingLeft: 8,
      ':last-child': {
        marginBottom: 0,
      },
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'var(--flex-end-mobile-center)',
      marginTop: 32,
      height: 80,
      padding: '0 var(--form-padding-horizontal)',
      width: '100%',
      '@media-mobile': {
        height: 'auto',
      },
    },
  },

  // ==============================
  // Email verification module
  // ==============================

  emailVerification: {
    container: {
      width: '100%',
      height: 'var(--view-height)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'var(--header-background-color)',
    },
    containerLoaderOnly: {
      backgroundColor: 'var(--color-background-primary)',
    },
    wrapper: {
      borderRadius: 8,
      width: 400,
      height: 340,
      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '16px 32px',
      backgroundColor: 'white',
    },
    wrapperLoaderOnly: {
      boxShadow: 'none',
    },
    successText: {
      marginTop: 16,
      fontSize: 14,
      textAlign: 'center',
    },
    checkmarkImage: {
      width: 64,
      marginBottom: 32,
    },
  },

  // ==============================
  // Generic module
  // ==============================

  generic: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 'var(--layout-app-max-width)',
      margin: '0 auto',
    },
    bottomButtonWrapper: {
      marginTop: 48,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'var(--flex-end-mobile-center)',
      gap: 8,
      height: 80,
      width: '100%',
      marginTop: 32,
      padding: '0 var(--form-padding-horizontal)',
      '@media-mobile': {
        flexWrap: 'wrap',
      },
    },
    title: {
      color: 'var(--color-primary)',
      fontSize: 'var(--font-size-title)',
      fontWeight: 500,
      maxWidth: 'calc(100vw - 2 * var(--form-padding-horizontal))',
      paddingTop: 16,
      paddingBottom: 16,
      width: '100%',
      ':readonly': {
        marginBottom: 20,
      },
      '@media-mobile': {
        paddingTop: 32,
      },
    },
    titleMobile: {
      display: 'none',
      '@media-mobile': {
        display: 'block',
        color: 'var(--color-primary)',
        fontSize: 'var(--font-size-title)',
        fontWeight: 500,
        padding: '32px var(--form-padding-horizontal)',
        width: '100%',
      },
    },
    loadingWrapperBelow: {
      borderRadius: 4,
      marginBottom: 24,
      padding: '16px 48px',
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    imageWrapper: {
      '@media-mobile': {
        maxWidth: 100,
      },
    },
    contactSectionWrapper: {
      background: 'white',
      borderRadius: 4,
      boxShadow: '0 0 8px 8px rgb(0 0 0 / 2%)',
      margin: '0 0 32px 0',
      padding: '48px var(--form-padding-horizontal)',
      position: 'relative',
      width: '100%',
    },
    descriptionWrapper: {
      background: 'white',
      borderRadius: 4,
      boxShadow: '0 0 8px 8px rgb(0 0 0 / 2%)',
      margin: '0 0 8px 0',
      padding: '48px var(--form-padding-horizontal)',
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    subtitle: {
      fontWeight: 500,
      fontSize: 'var(--font-size-p)',
      marginBottom: 32,
      width: '100%',
    },
    descriptionText: {
      fontSize: 'var(--font-size-p)',
      width: '100%',
    },
  },

  // ==============================
  // Jumio module
  // ==============================

  jumio: {
    iFrame: {
      width: 'calc(100% + 48px)',
      height: 600,
      marginLeft: -24,
      marginRight: -24,
      border: '1px solid var(--color-form-field-border)',
    },
    emailOptionWrapper: {
      padding: '16px 32px',
      backgroundColor: 'rgba(0,0,0,0.03)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '16px 0',
    },
    sendEmailLabel: {
      fontSize: 'var(--font-size-p)',
      fontWeight: 500,
    },
  },

  // ==============================
  // Merchant Banner component
  // ==============================

  merchantBanner: {
    outerWrapper: {
      marginBottom: 16,
    },
    innerWrapper: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#b51307',
      borderRadius: 'var(--form-field-border-radius)',
      padding: 4,
    },
    icon: {
      color: 'white',
    },
    label: {
      paddingLeft: 12,
      color: 'white',
    },
  },

  // ==============================
  // Payment Terms module
  // ==============================

  paymentTerms: {
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'var(--flex-end-mobile-center)',
      marginTop: 32,
      padding: '0 var(--form-padding-horizontal)',
      height: 80,
      width: '100%',

      '@media-mobile': {
        flexWrap: 'wrap',
      },
    },

    contentWrapper: {
      display: 'flex',
      gap: 112,
      width: '100%',

      '@media-mobile': {
        flexDirection: 'column',
        gap: 32,
      },
    },

    contentLeftWrapper: {
      flex: 1,
      display: 'flex',
      gap: 16,
      flexWrap: 'wrap',
      marginTop: 8,

      '@media-mobile': {
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: '100%',
      },
    },
    contentRightWrapper: {
      flex: '0 0 360px',
      width: 360,

      '@media-mobile': {
        flex: '0 0 200px',
        width: '100%',
      },
    },
    previewForm: {
      padding: '4px 24px',
      position: 'relative',
      top: -9,

      '@media-mobile': {
        padding: '12px 12px',
        top: 0,
      },
    },

    optionItem: {
      display: 'flex',
      padding: '0 calc(2 * var(--form-field-padding-horizontal))',
      alignItems: 'center',
      height: 72,
      gap: 8,
      width: '100%',
    },

    optionValue: { fontWeight: 600, marginBottom: 0 },

    optionSublabel: {
      fontSize: 16,
      opacity: 0.7,
    },
  },

  // ==============================
  // Selection module
  // ==============================

  selection: {
    wrapper: {},
    title: {},
    subtitle: {},
    optionsWrapper: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      '@media-mobile': {
        display: 'block',
      },
    },
    optionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 8px',
      minWidth: '50%',
      padding: 'var(--form-section-padding)',
      boxShadow: '0 0 8px 8px rgb(0 0 0 / 2%)',
      backgroundColor: 'var(--color-background-primary)',
      borderRadius: 8,
      transition: 'box-shadow 0.3s',
      ':hover': {
        boxShadow: 'var(--shadow-overlay)',
      },
      '@media-mobile': {
        minWidth: 0,
        width: '100%',
        margin: '0 0 16px 0',
        padding: 'var(--form-section-padding)',
      },
    },
    optionTitle: {
      fontSize: 'var(--font-size-title)',
    },
    optionDescription: {
      flexGrow: 1,
      fontSize: 16,
      fontWeight: 'normal',
      marginTop: 8,
      marginBottom: 32,
      '@media-mobile': {
        marginBottom: 24,
      },
    },
    optionIcon: {
      width: 64,
      marginLeft: 16,
      marginBottom: 32,
    },
    optionButton: {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 8,
    },
  },

  // ==============================================
  // Credit Account Identity Type selection module
  // ==============================================

  creditIdentityDataRetrievalSelection: {},

  // ==============================
  // Credit Confirmation module
  // ==============================

  creditConfirmation: {
    listItem: {
      fontSize: 'var(--font-size-p)',
      lineHeight: 1.8,
    },
    iconRow: {
      marginTop: 16,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
    },
    iconRowIcon: {
      height: 32,
      marginRight: 24,
    },
    buttonWrapper: {
      marginTop: 20,
      marginBottom: 20,
      textAlign: 'center',
    },
    description: {
      margin: '24px 0 24px 0',
    },
    icon: {
      marginTop: 32,
    },
    modalInnerWrapper: {
      alignItems: 'center',
      justifyContent: 'space-between',
      maxHeight: 360,
      maxWidth: 600,
      padding: 48,
      marginBottom: 32,
    },
  },

  // ==============================
  // Waiting
  // ==============================

  waiting: {
    descriptionWrapper: {
      background: 'white',
      borderRadius: 4,
      boxShadow: '0 0 8px 8px rgb(0 0 0 / 2%)',
      margin: '0 0 8px 0',
      padding: '48px var(--form-padding-horizontal)',
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    descriptionText: {
      fontSize: 'var(--font-size-p)',
      width: '100%',
      marginBottom: 48,
    },
    icon: {
      height: 144,
      marginBottom: '4vh',
      marginLeft: 'auto',
      marginRight: 'auto',
      filter: 'var(--form-icon-filter, none)',
    },
    loadingWrapper: {
      marginBottom: 24,
    },
    resendWrapper: {
      marginTop: 48,
      width: '100%',
    },
    resendLabel: {
      color: 'var(--color-secondary)',
      fontSize: 'var(--font-size-label)',
      marginBottom: 24,
    },
    resendErrorLabel: {
      color: 'var(--color-error)',
      fontSize: 'var(--font-size-sublabel)',
      marginTop: -16,
      marginBottom: 8,
    },
    resendInputWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      gap: 4,
      marginRight: 12,
      '@media-mobile': {
        width: '100%',
        marginRight: 0,
        paddingBottom: 6,
      },
    },
    resendButton: {
      height: 48,
      '@media-mobile': {
        width: '100%',
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 'var(--layout-app-max-width)',
      margin: '0 auto',
    },
  },

  // ==============================
  // Steps
  // ==============================

  steps: {
    wrapper: {
      width: '100%',
      padding: '8px 16px',
    },
    itemWrapper: {
      position: 'relative',
      height: 48,
      paddingLeft: 72,
      marginBottom: 32,
      display: 'flex',
      alignItems: 'center',
      ':last-child': {
        marginBottom: 0,
      },
      '@media-mobile': {
        marginBottom: 20,
      },
    },
    iconWrapper: {
      backgroundColor: 'var(--color-background-primary)',
      borderRadius: '50%',
      border: '2px solid var(--color-form-field-border)',
      position: 'absolute',
      left: 4,
      top: 4,
      height: 40,
      width: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.3s',
    },
    iconWrapperActive: {
      border: '2px solid var(--color-accent)',
      color: 'var(--color-accent)',
      left: 0,
      top: 0,
      height: 48,
      width: 48,
    },
    iconWrapperCompleted: {
      border: '2px solid var(--color-active)',
      color: 'var(--color-active)',
    },
    iconWrapperWarning: {
      border: '2px solid var(--color-warning)',
      backgroundColor: 'var(--color-warning)',
      color: 'white',
    },
    iconWrapperRejected: {
      border: '2px solid var(--color-error)',
      backgroundColor: 'var(--color-error)',
      color: 'white',
    },
    line: {
      backgroundColor: 'var(--color-form-field-border)',
      position: 'absolute',
      top: 24,
      height: 84,
      width: 2,
      left: 23,
      '@media-mobile': {
        height: 68,
      },
    },
    lineCompleted: {
      backgroundColor: 'var(--color-active)',
    },
    labelWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    label: {
      fontSize: '1.8rem',
      fontWeight: 500,
    },
    labelActive: {
      color: 'var(--color-primary)',
      fontWeight: 700,
    },
    labelCompleted: {
      color: 'var(--color-primary)',
      fontWeight: 500,
    },
    labelRejected: {
      color: 'var(--color-error)',
    },
    sublabel: {
      color: 'var(--color-secondary)',
      fontSize: 'var(--font-size-p-small)',
      paddingTop: 3,
    },
  },
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BobProductsApp_BusinessServices_States_ExternalViews_StatusLevelEnum {
    NONE = 'None',
    ROOT = 'Root',
    PAYMENT_DETAILS = 'PaymentDetails',
    PERSONAL_INFORMATION = 'PersonalInformation',
    CUSTOMER_VERIFICATION_AND_CONFIRMATION = 'CustomerVerificationAndConfirmation',
    DELIVERY_CONFIRMATION = 'DeliveryConfirmation',
    COMPLETION = 'Completion',
    TERMINATED = 'Terminated',
    SELECT_PAYMENT_DETAILS = 'SelectPaymentDetails',
    CREATE_APPLICATION = 'CreateApplication',
    SUBMIT_PERSONAL_INFORMATION = 'SubmitPersonalInformation',
    SUBMIT_PERSONAL_INFORMATION_STARTED = 'SubmitPersonalInformationStarted',
    SUBMIT_PERSONAL_INFORMATION_IN_PROGRESS = 'SubmitPersonalInformationInProgress',
    SUBMIT_PERSONAL_INFORMATION_DONE = 'SubmitPersonalInformationDone',
    SUBMIT_PERSONAL_INFORMATION_DEFAULT = 'SubmitPersonalInformationDefault',
    CUSTOMER_MATCHING_START = 'CustomerMatchingStart',
    CUSTOMER_MATCHING_IN_PROGRESS = 'CustomerMatchingInProgress',
    CUSTOMER_MATCHING_DONE = 'CustomerMatchingDone',
    VERIFY_IDENTITY = 'VerifyIdentity',
    CONFIRM_PAYMENT_PLAN = 'ConfirmPaymentPlan',
    SELECT_FINANCING_TERMS = 'SelectFinancingTerms',
    PAYOUT_IN_PROGRESS = 'PayoutInProgress',
    SIGN_CONTRACT = 'SignContract',
    VERIFY_EMAIL = 'VerifyEmail',
    CUSTOMER_COMPLETED = 'CustomerCompleted',
    CONFIRM_DELIVERY = 'ConfirmDelivery',
    COMPLETED = 'Completed',
    CONFIRMED = 'Confirmed',
    WITHDRAWN = 'Withdrawn',
    EXPIRED = 'Expired',
    DELETED = 'Deleted',
    CANCELLED = 'Cancelled',
    VERIFY_IDENTITY_PREPARATION = 'VerifyIdentityPreparation',
    VERIFY_IDENTITY_LINK_SMS_SENT_TO_CUSTOMER = 'VerifyIdentityLinkSmsSentToCustomer',
    VERIFY_IDENTITY_STARTED = 'VerifyIdentityStarted',
    VERIFY_IDENTITY_UPDATE_CUSTOMER = 'VerifyIdentityUpdateCustomer',
    VERIFY_IDENTITY_IN_PROGRESS = 'VerifyIdentityInProgress',
    CONFIRM_PAYMENT_PLAN_STARTED = 'ConfirmPaymentPlanStarted',
    CONFIRM_PAYMENT_PLAN_IN_PROGRESS = 'ConfirmPaymentPlanInProgress',
    SELECT_FINANCING_TERMS_STARTED = 'SelectFinancingTermsStarted',
    SELECT_FINANCING_TERMS_IN_PROGRESS = 'SelectFinancingTermsInProgress',
    SIGN_CONTRACT_STARTED = 'SignContractStarted',
    SIGN_CONTRACT_IN_PROGRESS = 'SignContractInProgress',
    FINALIZE_CONTRACT = 'FinalizeContract',
    RETRIEVE_IDENTITY_INFORMATION = 'RetrieveIdentityInformation',
    RETRIEVE_IDENTITY_INFORMATION_STARTED = 'RetrieveIdentityInformationStarted',
    RETRIEVE_IDENTITY_INFORMATION_IN_PROGRESS = 'RetrieveIdentityInformationInProgress',
    SCORING = 'Scoring',
    LOAN_OFFERING = 'LoanOffering',
    COOLOFF = 'Cooloff',
    PAYOUT = 'Payout',
    SCORING_CHECK_APPLICATION_ZEK = 'ScoringCheckApplicationZEK',
    SCORING_CHECK_APPLICATION_SCORE = 'ScoringCheckApplicationScore',
    SCORING_CREATE_CONTRACT = 'ScoringCreateContract',
    SCORING_CREATE_CONTRACT_STARTED = 'ScoringCreateContractStarted',
    SCORING_CREATE_CONTRACT_IN_PROGRESS = 'ScoringCreateContractInProgress',
    SCORING_CUSTOMER_MATCHING = 'ScoringCustomerMatching',
    SCORING_APPROVE_PAYOUT = 'ScoringApprovePayout',
    SCORING_COOLOFF_IN_PROGRESS = 'ScoringCooloffInProgress',
    SCORING_PAYOUT_IN_PROGRESS = 'ScoringPayoutInProgress',
}

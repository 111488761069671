import { AppConfig } from '../../../../types/app.types'
import { ApiError } from '../core/ApiError'

export type CreatePartnerOnboardingSessionLinkResponse = {
  url: string | null
}

export const createPartnerOnboardingSessionLink = async (params: {
  appConfig: AppConfig
}): Promise<CreatePartnerOnboardingSessionLinkResponse> => {
  const url = await fetch(`${params.appConfig.app.whitelabelPartnerOnboardingApiUrl}/create`)
    .then((res) => {
      if (!res.ok) {
        throw new ApiError(res, 'Error while creating partner onboarding session link')
      }
      return res
    })
    .then((res) => res.text())

  return { url }
}

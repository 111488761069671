import { compareVersions } from 'compare-versions'
import { detect } from 'detect-browser'
import { Button, Props as ButtonProps } from 'frr-web/lib/components/Button'
import { PlatformDataT, usePlatform } from 'frr-web/lib/hooks/usePlatform'
import { Div, H3, P } from 'frr-web/lib/html'
import { createStyled } from 'frr-web/lib/theme/util'
import { keys } from 'frr-web/lib/util'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserTypeFromSession } from '../../shared/helpers'
import { useAppTheme, useCSSStyles, useInlineStyle } from '../../shared/theme/app.theme'
import { TrackEventType } from '../../types/analytics.types'
import { FinancingObject, OnboardingChannel, ProductSettingsT } from '../../types/financing.types'
import { PlatformState, UserType } from '../../types/frontend.types'
import { Translate } from '../../types/helper.types'
import { useOptionalFinObj } from '../context/Data.context'
import { useProduct } from '../context/PartnerProduct.context'
import { useTrackEvent } from '../hooks/useTrackEvent'
import { Header } from '../scaffold/header/Header'
import { Notification } from './Notification.provider'
import { useAppConfig } from './AppConfigData.provider'
import { FinancingSessionStorage } from './FinancingSession.provider'

type ComponentProps = {
  title: string
  description: string
  button: ButtonProps
  onIgnore: () => void
}

export const PlatformDetection = (props: ComponentProps) => {
  const theme = useAppTheme()
  const getCSSStyles = useCSSStyles(theme, 'platformDetection')({})
  const getInlineStyles = useInlineStyle(theme, 'platformDetection')({})

  return (
    <Div {...getCSSStyles('container')}>
      <Div dataTestId='email-verification-success' {...getCSSStyles('wrapper')}>
        <img src={'/warning.svg'} alt='checkmark' style={getInlineStyles('warningImage').style} />
        <H3 label={props.title} {...getCSSStyles('title')} />
        <P label={props.description} {...getCSSStyles('description')} />

        <Button {...props.button} />

        <Link href='#' onClick={props.onIgnore} {...getCSSStyles('ignoreLink')}>
          <P label={'platformDetection.ignore'} />
        </Link>
      </Div>
    </Div>
  )
}

const Link = createStyled('a')

const allBrowserTypes: Array<keyof PlatformDataT['browser']> = [
  'chrome',
  'chromeIos',
  'edge',
  'edgeAndroid',
  'edgeChromium',
  'edgeIos',
  'firefox',
  'firefoxIos',
  'ie',
  'safari',
  'samsungBrowser',
]

const browserDetect = detect()

// Hardcoded config
const platformConfig: Partial<
  Record<keyof PlatformDataT['os'], Partial<Record<keyof PlatformDataT['browser'], string>>>
> = {
  ios: {
    safari: '15.0.2',
  },
  android: {
    chrome: '94',
    samsungBrowser: '14',
    edgeChromium: '94',
    edgeAndroid: '0',
    edge: '0',
  },
}

const buttonProps = (params: { redirectLink: string; t: Translate }): ButtonProps => ({
  onClick: () => {
    Notification.info(params.t('platformDetection.copyLink'))
    navigator.clipboard.writeText(params.redirectLink)
  },
  label: 'platformDetection.copyLink',
})

const mapStateToProps: Record<
  PlatformState,
  (params: { onIgnore: () => void; redirectLink: string; t: Translate }) => ComponentProps
> = {
  [PlatformState.UnsupportedBrowser]: (params) => ({
    title: 'platformDetection.unsupportedBrowser.title',
    description: 'platformDetection.unsupportedBrowser.description',
    button: buttonProps(params),
    onIgnore: params.onIgnore,
  }),
  [PlatformState.UnsupportedDevice]: (params) => ({
    title: 'platformDetection.unsupportedDevice.title',
    description: 'platformDetection.unsupportedDevice.description',
    button: buttonProps(params),
    onIgnore: params.onIgnore,
  }),
  [PlatformState.UnsupportedOS]: (params) => ({
    title: 'platformDetection.unsupportedOS.title',
    description: 'platformDetection.unsupportedOS.description',
    button: buttonProps(params),
    onIgnore: params.onIgnore,
  }),
  [PlatformState.UnsupportedBrowserVersion]: (params) => ({
    title: 'platformDetection.unsupportedVersion.title',
    description: 'platformDetection.unsupportedVersion.description',
    button: buttonProps(params),
    onIgnore: params.onIgnore,
  }),
}

const computePlatformState = (params: {
  finObj?: FinancingObject
  platformData: PlatformDataT
  product: ProductSettingsT
  userType: UserType
}): PlatformState | null => {
  if (!params.finObj) {
    return null
  }

  // The check applies only to the customer verification in the BP flows
  if (
    !(
      [OnboardingChannel.BPL_RETAIL, OnboardingChannel.BPL_ECOMMERCE].includes(
        params.product.onboardingChannel as OnboardingChannel,
      ) && params.userType === UserType.CustomerVerification
    )
  ) {
    return null
  }

  // if finObj has an error or a rejection or the ID verification is passed, platform doesn't matter
  if (
    params.finObj.errorInfo.error.isError ||
    params.finObj.errorInfo.error.isRejection ||
    params.finObj.verificationInfo.isBobIdVerificationSuccessful
  ) {
    return null
  }

  try {
    if (params.platformData.isDesktop) {
      return PlatformState.UnsupportedDevice
    }

    const matchingOS = keys(platformConfig).find((k) => !!params.platformData.os[k])

    if (!matchingOS) {
      return PlatformState.UnsupportedOS
    }

    const browserOptions = keys(platformConfig[matchingOS!]!)

    const nonBrowserOptions = allBrowserTypes.filter((t) => !browserOptions.includes(t))

    const nonMatchingBrowser = nonBrowserOptions.find((k) => !!params.platformData.browser[k])

    if (nonMatchingBrowser) {
      return PlatformState.UnsupportedBrowser
    }

    const matchingBrowser = browserOptions.find((k) => !!params.platformData.browser[k])

    if (!matchingBrowser) {
      return PlatformState.UnsupportedBrowser
    }

    const browserVersion = platformConfig[matchingOS!]![matchingBrowser!]!

    if (browserDetect?.version) {
      const isBelow = compareVersions(browserDetect.version, browserVersion) === -1
      if (isBelow) {
        return PlatformState.UnsupportedBrowserVersion
      }
    }
  } catch (err) {}

  return null
}

const usePlatformWarning = () => {
  const { product } = useProduct()
  const finObj = useOptionalFinObj()
  const platformData = usePlatform()

  const userType = getUserTypeFromSession()

  const [platformState, setPlatformState] = useState<PlatformState | null>(
    computePlatformState({
      finObj,
      platformData,
      product,
      userType,
    }),
  )

  return {
    platformState,
    ignorePlatformState: () => setPlatformState(null),
  }
}

const TestEnvs = ['dev', 'test']

export const PlatformDetectionProvider = (props: { children: ReactNode }) => {
  const { appConfig } = useAppConfig()
  const { t } = useTranslation()

  const { platformState, ignorePlatformState } = usePlatformWarning()

  // Do deactivate it on test except if query param detect-platform is active ('on)
  const searchParams = new URLSearchParams(window.location.search)

  const isActive =
    !TestEnvs.includes(appConfig.app.whitelabelEnvironment) ||
    searchParams.get('detect-platform') === '1'
  // Required for cypress testing
  const isInactive = searchParams.get('detect-platform') === '0'

  const isInvalidPlatform = isActive && !isInactive && platformState

  // Track unsupported platform
  const trackEvent = useTrackEvent('PlatformDetectionProvider')

  useEffect(() => {
    if (isInvalidPlatform) {
      trackEvent({
        type: TrackEventType.BplVerifyIDUnsupportedPlatform,
        platformState,
      })
    }
  }, [isInvalidPlatform, platformState])

  if (isInvalidPlatform) {
    const session = FinancingSessionStorage.getSession()
    const redirectLink = `${window.location.origin}/?session=${session}&language=${
      window.location.pathname.split('/')[1]
    }`

    return (
      <>
        <Header />
        <PlatformDetection
          {...mapStateToProps[platformState]({
            redirectLink,
            t,
            onIgnore: () => {
              ignorePlatformState()
              trackEvent({
                type: TrackEventType.BplVerifyIDUnsupportedPlatform,
                platformState,
                isIgnored: true,
              })
            },
          })}
        />
      </>
    )
  }

  return <>{props.children}</>
}

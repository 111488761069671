import { useQuery } from '@tanstack/react-query'
import { AppConfig } from '../../types/app.types'

/*
 * App query keys
 */

export const AppQueryKey = {
  AppConfig: () => ['Config'] as const,
  User: () => ['User'] as const,
}

/*
 * Config Queries
 */

const fetchConfig = async () => {
  const response = await fetch('/api/config').catch((error) => {
    throw new Error(error)
  })
  return response.json()
}

export const useAppConfigQuery = () =>
  useQuery<AppConfig>({
    queryKey: AppQueryKey.AppConfig(),
    queryFn: fetchConfig,
    staleTime: Infinity, // No re-fetching for the app configuration
  })

import { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  ProductQueryParams,
  useProductConfigurationQuery,
  useProductQuery,
} from '../../data/queries/product.queries'
import { appActions, appSelectors } from '../../data/redux/appState.redux'
import { getDevPartner } from '../../shared/helpers.withEnvVars'
import { ProductSettingsT } from '../../types/financing.types'
import { PartnerFrontendConfiguration } from '../../types/frontend.types'
import { useHostname } from '../hooks/useHostname'
import { useAppConfig } from '../providers/AppConfigData.provider'
import { Loading } from '../scaffold/Loading'
import { NotFound } from '../scaffold/NotFound'
import { useOptionalFinObj } from './Data.context'
import { useTracker } from './Tracker.context'

// Get Product and product configuration

type ProductContextT = {
  product: ProductSettingsT
  configuration: PartnerFrontendConfiguration
}

export const useProduct = (): ProductContextT => {
  const partnerProduct = useSelector(appSelectors.partnerProduct)

  if (!partnerProduct) {
    throw new Error(`Product not found.`)
  }

  return partnerProduct
}

export const PartnerProductProvider = (props: { children: ReactNode }) => {
  const { appConfig } = useAppConfig()
  const { tracker } = useTracker()

  // Get product params
  const [searchParams] = useSearchParams()
  const optionalFinObj = useOptionalFinObj()
  const { urlHostname } = useHostname()

  const [productParams, setProductParams] = useState<ProductQueryParams>({})

  const isPartnerOnboarding = searchParams.get('isPartnerOnboarding')
  const partner = searchParams.get('partner')
  const pid = searchParams.get('pid')

  useEffect(() => {
    let productId: undefined | string = undefined

    if (optionalFinObj) {
      productId = optionalFinObj.contextInfo.partnerInfo.partnerVariantId || ''
    } else {
      productId = partner || pid || getDevPartner() || ''
    }

    if (productId) {
      setProductParams({ productId })
    } else if (isPartnerOnboarding) {
      setProductParams({ isPartnerOnboarding })
    } else if (urlHostname) {
      setProductParams({ urlHostname })
    }
  }, [optionalFinObj, partner, pid, isPartnerOnboarding])

  // Load product & procuct configuration
  const {
    data: product,
    isLoading: isProductLoading,
    isError: isProductError,
  } = useProductQuery(productParams)

  const {
    data: configuration,
    isLoading: isConfigurationLoading,
    isError: isConfigurationError,
  } = useProductConfigurationQuery(product)

  // Set product & configuration in redux
  const partnerProduct = useSelector(appSelectors.partnerProduct)
  const dispatch = useDispatch()
  useEffect(() => {
    if (product && configuration) {
      appActions(dispatch).setPartnerProduct({ product, configuration })
    }
  }, [product, configuration, dispatch])

  // Initialise Application Insights tracker with product information
  useEffect(() => {
    // Set Application Insights context
    if (product && appConfig.app.whitelabelEnvironment !== 'dev') {
      tracker.addPartner(product)
    }
  }, [product?.variantId])

  // Render component depending on loading state
  if (isProductLoading || isConfigurationLoading) {
    return <Loading label='loading product' />
  }

  if (!product || !configuration) {
    return <NotFound label='product for partner not found' />
  }

  if (isProductError || isConfigurationError) {
    return <NotFound label='error while loading partner' />
  }

  if (!partnerProduct) {
    return <Loading label='loading product' />
  }

  return <>{props.children}</>
  // return (
  //   <ProductContext.Provider
  //     value={{
  //       product,
  //       configuration,
  //     }}
  //   >
  //     {props.children}
  //   </ProductContext.Provider>
  // )
}
